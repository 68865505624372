import { useEffect, useState } from "react";
import useTrig from "react-use-trig";
import Scene from "../components/Scene";
import { questForKey } from "../engine/story";
import { shared, useEvent } from "../engine/useStory";
import { FightEvent } from "../events/fight";
import Wheel from "../stories/Wheel";

export default function AdventureScene() {
  const { emit, eventConfig, eventKey } = shared.story;
  const quest = eventConfig?.quest;
  const { trig } = useTrig();
  const [center, setCenter] = useState<boolean>(false);
  const [eventComponent, setEventComponent] = useState<JSX.Element>(<></>);

  const Q = () => {
    if (!quest) return <>
    </>;
    const Q = questForKey(quest.key);
    return <Q quest={quest}></Q>;
  }
  useEffect(() => {
    if (eventKey !== 'fight') { setEventComponent(<></>)}
  }, [eventKey]);
  useEvent({
    fight: () => { setEventComponent(<FightEvent></FightEvent>) },
    win: () => {
      trig([
        { t: 1000, f: () => { setCenter(true) }},
        { t: 5000, f: () => { emit('enter_forest'); }}
      ]);
    }
  });

  return <Scene>
    <Q/>
    { eventComponent }
    <Wheel hide={!!quest} intro={false} fixed={true} falling={false} bounce={false} level={eventConfig?.data?.level || 1} center={center} outro={false}></Wheel>
  </Scene>
}