import { loadAsset } from '../cyoa-manifest';
import { Knot } from '../util/Knot';
import './house-collage.scss';

export interface HouseCollageProps {
  show: boolean;
}

export default function HouseCollage(props: HouseCollageProps): JSX.Element {
  return Knot({
    _: props.show,
    x: (p) => <div {...p}>
      <img className='house-open' src={loadAsset('img/house-full.gif')} alt="A House"></img>
    </div>,
    names: ['HouseCollage'],
  })
};

