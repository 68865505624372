import { useState } from "react";
import useTrig from "react-use-trig";
import { ChoiceConfig, ChoiceOptionConfig } from "../engine/story";
import { shared } from "../engine/useStory";
import './NarratorChoice.scss'

export default function NarratorChoice(props: { choice: ChoiceConfig, onChoose: (option: ChoiceOptionConfig) => void }) {
  const { emit } = shared.story;
  const [ chosenOption, setChosenOption ] = useState<ChoiceOptionConfig|undefined>(undefined);
  const [ showResult, setShowResult ] = useState<boolean>(false);
  const { trig } = useTrig();

  const onChoose = (e: React.MouseEvent, option: ChoiceOptionConfig) => {
    e.stopPropagation();
    setChosenOption(option);
    trig({ t: 1000, f: () => setShowResult(true) });
    props.onChoose(option);
  };

  const onStep = (e: React.MouseEvent) => {
    if (chosenOption) { emit(chosenOption.result.event); }
    setChosenOption(undefined);
    setShowResult(false);
  }

  return <>
    <div className='NarratorChoice'>
      <div className='prompt'>{props.choice.prompt}</div>
      {
        !chosenOption
          ? props.choice.options.map((option, i) => <div key={i} className='basic-button option' onClick={(e) => onChoose(e, option)}>
            {option.text}
          </div>)
          : <div className="chosen">{chosenOption.text}</div>
      }
      {
        showResult && <div className='result'>
          {chosenOption?.result.text && <div className="result-text">{chosenOption.result.text}</div>}
        </div>
      }
    </div>
    <div className="next-button">
      {showResult && <div className={['basic-button', !showResult ? ' hidden' : ''].join(' ')} onClick={onStep}>→</div>}
    </div>
  </>
}