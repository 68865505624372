import './HUD.scss'

interface HUDProps {
  showProgress: boolean;
}

export default function HUD(props: HUDProps) {
  return <div className='HUD'>
    {props.showProgress
      ? <div className="progress"
        style={{
          backgroundImage: `url('/img/wheel.png')`,
          backgroundPosition: 'center',
          backgroundSize: '160%',
        }}
        >
      </div>
      : <></>}
  </div>
}