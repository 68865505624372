import { useEffect, useState } from "react";
import Quest from "../components/Quest";
import { QuestProps } from "../cyoa-manifest";

function makeCardUrl(slug: string, params?: Record<string, any>): string {
  const query = params ? `?${new URLSearchParams(params).toString()}` : ''
  return `${process.env.REACT_APP_CARD_URL}/c/${slug}${query}`
}

export default function AdventureCard(props: QuestProps) {
  const { quest } = props
  const card = quest.card

  const [success, setSuccess] = useState<boolean>(false)

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (event.data && typeof event.data === 'object' && event.data.__adventure) {
        console.log(event.data.data)
        console.log(event.data.data.success)
        if (event.data.data.success) {
          console.log('success')
          setSuccess(true)
        }
      }
    }

    window.addEventListener("message", handler)

    return () => window.removeEventListener("message", handler)
  }, [])

  const url = card && makeCardUrl(card, props.quest.data)
  console.log(url)

  return <Quest success={success} quest={props.quest}>
    {url && <iframe
      src={url}
      width="100%" height="100%"
      style={{ border: 'none' }}
    ></iframe>}
  </Quest>
}

