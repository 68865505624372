import { useState } from "react";
import { QuestProps } from "../cyoa-manifest";
import Quest from "../components/Quest";
import './nas.scss';
import useTrig from 'react-use-trig';
import Die from '../stories/Die';

function isDieDigit (c: string) {
  const n = Number(c);
  return (!isNaN(n) && n % 1 === 0 && n > 0 && n < 7);
}

export default function NasQuest(props: QuestProps) {
  const data = props.quest.data as {
    dice: number[];
    caption: string;
    prompts: string[];
  };

  const [success, setSuccess] = useState<boolean>(false);
  const [rollCheckerTimeout, setRollCheckerTimeout] = useState<NodeJS.Timeout|undefined>(undefined);
  const [values, setValues] = useState<number[]>([6,6,6])
  const { trig } = useTrig();

  const onRoll = (index: number, value: number) => {
    const checkValues = values.map((v, i) => i === index ? value : v)
    setValues(checkValues);
    rollCheckerTimeout && clearTimeout(rollCheckerTimeout);
    const [timeout] = trig({ t: 1000, f: () => {
      if (checkValues.every((v, i) => (data.dice as number[])[i] === v)) {
        setSuccess(true);
      }
    }});
    setRollCheckerTimeout(timeout);
  };

  return <Quest success={success} quest={props.quest}>
    <div className="NasQuest">
      <div className="prompt">
        {(data.prompts as string[]).map((p, i) => <div key={i}>{p}</div>)}
      </div>
      <div className="dice-container">
        {(data.dice as number[]).map((d, i) => <Die key={i} locked={success} index={i} onRoll={(n) => onRoll(i, n)}/>)}
      </div>
      <div className="caption">
        {
          (data.caption as string)
            .split('')
            .map((c, i) => <span key={i}className={`${isDieDigit(c) ? 'winning' : ''}`}>{c}</span>)
        }
      </div>
    </div>
  </Quest>;
}