import { loadAsset } from '../cyoa-manifest';
import { Knot } from '../util/Knot';
import './Wheel.scss';
import Illusion from '../stories/Illusion';

interface WheelProps {
  hide: boolean;
  falling: boolean;
  fixed: boolean;
  bounce: boolean;
  intro: boolean;
  outro: boolean;
  center: boolean;
  level: number;
}

export default function Wheel(props: WheelProps) {

  return Knot({
    _: true,
    x: p => <div {...p}>
      {Knot({
        _: true,
        names: ['wheel-container'],
        bounce: props.bounce,
        falling: props.falling,
        fixed: props.fixed,
        intro: props.intro,
        outro: props.outro,
        center: props.center,
        ['level-' + props.level]: true,
        x: p => <div {...p}>
          {Knot({_: true, x: p => <Illusion morph={!props.fixed} round={props.fixed}></Illusion> })}
          <img className="hero" src={loadAsset('img/guy.gif')} alt="hero"/>
          <img className="wheel-image" src={loadAsset('img/wheel.png')} alt="wheel"/>
        </div>,
      })}
    </div>,
    names: ['Wheel'],
    hidden: props.hide,
  })
}