export enum EditableCollectionType {
  Cards = 'cards',
  Themes = 'themes'
}

export enum EditableType {
  React = 'react',
  Theme = 'theme',
}

export enum EditablePropertyTypes {
  JSON = 'json',
  String = 'string',
}

export enum EditableLangTypes {
  Javascript = 'javascript',
  SCSS = 'scss',
  JSON = 'json',
}

export interface Editable {
  type: EditableType
}

export interface IEditableOps {
  is: (editable: any, editProperty?: string) => editable is Editable
  propertyInfo: Record<string, {
    type: EditablePropertyTypes,
    lang: EditableLangTypes,
  }>
  codeFrameTemplate: {
    head: Record<string, (id: string, editable?: Editable) => string|JSX.Element>
    tail: Record<string, (id: string, editable?: Editable) => string|JSX.Element>
  }
}