import { Editable, EditablePropertyTypes, IEditableOps } from './editable.interface'
import { ReactEditableOps } from './EditableReact'

const supportedEditables: IEditableOps[] = [
  ReactEditableOps,
]

export class EditableUtil {
  static memberOf(value: string, enumeration: Record<string, string>): boolean {
    return Object.values(enumeration).includes(value)
  }

  private static transformPackForStorage: Record<EditablePropertyTypes, (editingValue: any) => any> = {
    [EditablePropertyTypes.String]: v => v,
    [EditablePropertyTypes.JSON]: v => v ? JSON.parse(v) : null,
  }

  private static transformUnpackForEditing: Record<EditablePropertyTypes, (storedValue: any) => any> = {
    [EditablePropertyTypes.String]: v => v,
    [EditablePropertyTypes.JSON]: v => v ? JSON.stringify(v) : '',
  }

  static unpackForEditing(editable: Editable, editProperty: string, value: any) {
    const info = EditableUtil.getPropertyInfo(editable, editProperty)
    const transform = info ? EditableUtil.transformUnpackForEditing[info.type] : ((v: any) => v)
    return transform(value)
  }

  static packForStorage(editable: Editable, editProperty: string, value: any) {
    const info = EditableUtil.getPropertyInfo(editable, editProperty)
    const transform  = info ? EditableUtil.transformPackForStorage[info.type] : ((v: any) => v)
    return transform(value)
  }

  static getPropertyInfo(editable: Editable, editProperty: string) {
    for (const ops of supportedEditables) {
      if (ops.is(editable)) {
        return ops.propertyInfo[editProperty]
      }
    }
  }

  static defaultCodeFrame = Object.freeze({
    head: '',
    tail: '',
  })
  
  static getCodeFrame(editProperty: string, id: string, editable?: Editable|null): Record<string, string|JSX.Element> {
    if (!editable) {
      return EditableUtil.defaultCodeFrame
    }
    for (const ops of supportedEditables) {
      if (ops.is(editable, editProperty)) {
        return {
          head: ops.codeFrameTemplate.head[editProperty](id, editable),
          tail: ops.codeFrameTemplate.tail[editProperty](id, editable),
        }
      }
    }
  
    return EditableUtil.defaultCodeFrame
  }

}