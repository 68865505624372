import { useEffect, useRef, useState } from "react";
import { QuestProps } from "../cyoa-manifest";
import Quest from "../components/Quest";
import './jeopardy.scss';

export default function JeopardyQuest(props: QuestProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const data = props.quest.data;
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => { inputRef.current?.focus(); }, []);

  const onInput = (e: React.FormEvent) => {
    const value = inputRef.current?.value;
    if (value?.toLowerCase() === data?.question.toLowerCase()) {
      if (inputRef.current) { inputRef.current.readOnly = true; }
      setSuccess(true);
    }
  }

  return data
    ? <Quest success={success} quest={props.quest}>
      <div className="JeopardyQuest">
        <div className="answer">
          {data.answer.toUpperCase()}
        </div>
        <div className={`question ${success ? 'correct' : ''}`}>
          <input ref={inputRef} onInput={onInput} ></input>
        </div>
      </div>
    </Quest>
    : <></>;
}