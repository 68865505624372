import { RefObject } from 'react'
import './Preview.scss'

export default function Preview(props: {
  src: string
  previewRef: RefObject<HTMLIFrameElement>
}) {
  if (!props.src) {
    return <></>
  }
  return <iframe
    ref={props.previewRef}
    className="preview"
    src={props.src}
  ></iframe>
}