import { useState } from 'react';
import { loadAsset } from '../cyoa-manifest';
import Confetti from '../stories/Confetti';
import Forest from '../stories/Forest';
import Rings from '../stories/Rings';
import House from '../stories/House';
import useTrig from 'react-use-trig';
import './ring.scss';
import { shared, useEvent } from '../engine/useStory';
import { Knot } from '../util/Knot';
import HouseCollage from '../stories/HouseCollage';
import Fellowship from '../stories/Fellowship';
import Fireworks from '../stories/Fireworks';
import Logo from '../stories/Logo';
import Typewriter from '../stories/Typewriter';
import SoundEffect from '../special_effects/SoundEffect';

export default function RingEvent() {
  const { emit } = shared.story;

  const { trig } = useTrig();

  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [mergeRings, setMergeRings] = useState<boolean>(false);
  const [showRing, setShowRing] = useState<boolean>(false);
  const [selectRing, setSelectRing] = useState<boolean>(false);
  const [showDialogue1, setShowDialogue1] = useState<boolean>(false);
  const [showHouse, setShowHouse] = useState<boolean>(false);
  const [zoomInHouse, setZoomInHouse] = useState<boolean>(false);
  const [zoomOutHouse, setZoomOutHouse] = useState<boolean>(false);
  const [zoomOutHouseSafari, setZoomOutHouseSafari] = useState<boolean>(false);
  const [walking, setWalking] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showHero, setShowHero] = useState<boolean>(false);
  const [bonusContent, setShowBonusContent] = useState<boolean>(false);

  useEvent({
    ring: () => {
      trig([
        { t: 9000, f: () => { setShowHero(true); }},
        { t: 14000, f: () => { setShowDialogue1(true); }},
        { t: 17000, f: () => { setShowRing(true); }},
      ]);
    },
    merge: () => {
      setSelectRing(true);
      setMergeRings(true);
      trig([
        { t: 7000, f: () => emit('confetti') },
      ])
    },
    confetti: () => {
      setShowConfetti(true);
      trig([
        { t: 2000, f: () => setWalking(true) },
        { t: 20000, f: () => setShowHouse(true) },
        { t: 22000, f: () => emit('home') },
      ])
    },
    home: () => {
      trig([
        { t: 2000, f: () => { setZoomInHouse(true) } },
        { t: 14000, f: () => { emit('message') } },
      ])
    },
    message: () => {
      setShowMessage(true);
      trig([
        { t: 22000, f: () => { setShowMessage(false) } },
        { t: 53000, f: () => { emit('fireworks') } },
      ]);
    },
    fireworks: () => {
      setZoomInHouse(false);
      const userAgent = navigator.userAgent.toLowerCase();
      // FireFox: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:86.0) Gecko/20100101 Firefox/86.0"
      // Chrome: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.192 Safari/537.36"
      // Safari: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Safari/605.1.15"
      (userAgent.includes('safari') && !userAgent.includes('chrome'))
        ? setZoomOutHouseSafari(true)
        : setZoomOutHouse(true);

      trig([
        { t: 4000, f: () => { setShowBonusContent(true) } },
      ]);
    },
  });

  const onClickRing = () => {
    emit('mazel');
    setShowDialogue1(false);
  };

  const confettiKnot = Knot({
    _: showConfetti,
    x: (p) => <Confetti {...p} fade={false} onClick={() => {}}/>,
  })

  const winSoundKnot = Knot({
    _: zoomOutHouse || zoomOutHouseSafari,
    x : p => <SoundEffect audio={{ file: 'audio/smash_win.mp3' }} onComplete={() => {}}></SoundEffect>,
  })


  const fireworksKnot = Knot({
    _: zoomOutHouse || zoomOutHouseSafari,
    names: ['fireworks'],
    x: (p) => <div {...p}>
      <Fireworks></Fireworks>
      <Fireworks></Fireworks>
    </div>,
  })

  const logoKnot = Knot({
    _: true,
    x: (p) => <div {...p} ><Logo></Logo></div>,
    names: ['logo']
  })

  const typewriterKnot = Knot({
    _: showMessage,
    x: (p) => <div {...p}>
      <Typewriter lines={['gg', 'happy engagement bb', 'ilysm', '<3']}></Typewriter>
    </div>,
    names: ['message']
  })
  const downloadKnot = Knot({
    _: bonusContent,
    x: (p) => <a {...p} href={loadAsset('img/Fractal Forest Exclusive Bonus Content.zip')} download='Fractal Forest Exclusive Bonus Content.zip'>
      Download Exclusive<br/>Bonus Content
      <img alt="download-icon" src={loadAsset('img/green_army_man.png')} />
      {winSoundKnot}
    </a>,
    names: ['download-container', 'basic-button']
  })

  return <>
    {Knot({
      _: true,
      names: ['RingEvent'],
      'hide-hero': !showHero,
      'zoom-in': zoomInHouse,
      'zoom-out': zoomOutHouse,
      'zoom-out-safari': zoomOutHouseSafari,
      x: p => <div {...p} onTouchMove={e => e.preventDefault()}>
        <div className='path' style={{ backgroundImage: `url(${loadAsset('img/path.png')})`}}></div>
        <Forest></Forest>
        <House show={showHouse}></House>
        <Rings show={mergeRings}></Rings>
        <Fellowship
          show={true}
          showRing={showRing}
          selectRing={selectRing}
          showDialogue1={showDialogue1}
          walking={walking}
          onClickRing={onClickRing}
        />
        {confettiKnot}
        <HouseCollage show={zoomInHouse || zoomOutHouse || zoomOutHouseSafari}></HouseCollage>
        {logoKnot}
      </div>,
    })}
    {typewriterKnot}
    {fireworksKnot}
    {downloadKnot}
  </>;
}