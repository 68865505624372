import { useCallback, useEffect, useState } from "react";
import SoundEffect from "../special_effects/SoundEffect";
import { AudioConfig, QuestConfig } from "../engine/story";
import { shared } from "../engine/useStory";
import './Quest.scss'
import useTrig from "react-use-trig";

interface InternalQuestProps {
  quest: QuestConfig;
  success: boolean;
  children?: JSX.Element|string;
}

export default function Quest(props: InternalQuestProps) {
  const { emit } = shared.story;
  const [audio, setAudio] = useState<AudioConfig|undefined>(undefined);
  const { trig } = useTrig();
  
  const onSoundEffectComplete = useCallback(() => {
    setAudio(undefined)
    emit(props.quest.successEvent);
  }, [emit, props.quest]);

  useEffect(() => {
    if (props.success) {
      const successAudio = props.quest.successAudio;
      setAudio(successAudio);
      if (!successAudio) {
        trig({ t: 4000, f: () => { onSoundEffectComplete(); }});
      }
    }
  }, [trig, props.success, props.quest, onSoundEffectComplete]);
  return <div className="Quest">
    {props.children}
    {props.success && audio && <SoundEffect audio={audio} onComplete={onSoundEffectComplete} ></SoundEffect>}
  </div>
}