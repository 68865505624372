import './Scene.scss';
interface SceneProps {
  onClick?: () => any;
  children?: JSX.Element|(JSX.Element|undefined)[]|string;
}

export default function Scene(props: SceneProps) {
  return <div
    className="Scene"
    onClick={() => props.onClick ? props.onClick() : undefined }
    >
    {props.children}
  </div>
}