import { Knot } from '../util/Knot';
import './Illusion.scss';

export default function Illusion(props: {morph: boolean, round: boolean}) {

	return Knot({
    _: true,
    morph: props.morph,
    round: props.round,
    names: ['Illusion'],
    x: p => <div {...p}>
    <div className="square black">
      <div className="square">
        <div className="square black">
          <div className="square">
            <div className="square black">
              <div className="square">
                <div className="square black">
                  <div className="square">
                    <div className="square black">
                      <div className="square">
                        <div className="square black">
                          <div className="square">
                            <div className="square black">
                              <div className="square">
                                <div className="square black">
                                  <div className="square">
                                    <div className="square black">
                                      <div className="square">
                                        <div className="square black">
                                          <div className="square">
                                            <div className="square black">
                                              <div className="square">
                                                <div className="square black">
                                                  <div className="square">
                                                    <div className="square black">
                                                      <div className="square">
                                                        <div className="square black">
                                                          <div className="square">
                                                            <div className="square black">
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>,
  });
}