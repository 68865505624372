import './logo.scss';
export interface LogoProps {
}

export default function Logo(props: LogoProps): JSX.Element {
  return <div className="logo-letters" dangerouslySetInnerHTML={{
    __html: '<div><span style="color:#ff0000;">F</span><span style="color:#ff4000;">R</span><span style="color:#ff7f00;">A</span><span style="color:#ffaa00;">C</span><span style="color:#ffd400;">T</span><span style="color:#ffff00;">A</span><span style="color:#80ff00;">L</span></div><div><span style="color:#00ff00;">F</span><span style="color:#00ff55;">O</span><span style="color:#00ffaa;">R</span><span style="color:#00ffff;">E</span><span style="color:#0080ff;">S</span><span style="color:#0000ff;">T</span></div>'
    }}
  ></div>
};

