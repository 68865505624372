import { Editable, EditableLangTypes, EditablePropertyTypes, IEditableOps } from './editable.interface'
import { EditableUtil } from './EditableUtil'
import HL from './HL'

export enum ReactEditableProperties {
  Component = 'component',
  SCSS = 'scss',
  DefaultProps = 'defaultProps',
}

export interface ReactEditable extends Editable, Partial<Record<ReactEditableProperties, string>> {}

export const ReactEditableOps: IEditableOps = class {
  static is(o: any, editProperty?: string): o is ReactEditable {
    return o && o.type === 'react'
      && (!editProperty || EditableUtil.memberOf(editProperty, ReactEditableProperties))
  }

  static codeFrameTemplate: {
    head: Record<ReactEditableProperties, (id: string, editable?: ReactEditable) => string|JSX.Element>
    tail: Record<ReactEditableProperties, (id: string, editable?: ReactEditable) => string|JSX.Element>
  } = {
    head: {
      [ReactEditableProperties.Component]: (id, card) => {
        const props = card ? `props=${JSON.stringify(card.defaultProps || {}, null, 2)}` : ''
        const code = `function ${id}(${props}) {`
        return <HL lang="javascript" code={code}></HL>
      },
      [ReactEditableProperties.SCSS]: (id, card) => <HL lang="html" code={`<style title="${id}">`} />,
      [ReactEditableProperties.DefaultProps]: (id, card ) => <HL lang="text" code={`JSON Properties`} />,
    },
    tail: {
      [ReactEditableProperties.Component]: (id, card) => <HL lang="javascript" code="}" />,
      [ReactEditableProperties.SCSS]: (id, card) => <HL lang="html" code={`</style>`} />,
      [ReactEditableProperties.DefaultProps]: (id, card) => ``,
    },
  }

  static propertyInfo: Record<ReactEditableProperties, {
    type: EditablePropertyTypes,
    lang: EditableLangTypes,
  }> = {
    [ReactEditableProperties.Component]: {
      type: EditablePropertyTypes.String,
      lang: EditableLangTypes.Javascript,
    },
    [ReactEditableProperties.SCSS]: {
      type: EditablePropertyTypes.String,
      lang: EditableLangTypes.SCSS,
    },
    [ReactEditableProperties.DefaultProps]: {
      type: EditablePropertyTypes.JSON,
      lang: EditableLangTypes.JSON,
    }
  }
}