import { useEffect, useState } from 'react';
import { AudioConfig, ChoiceOptionConfig } from '../engine/story';
import { shared } from '../engine/useStory';
import './Narrator.scss';
import NarratorChoice from './NarratorChoice';
import SoundEffect from '../special_effects/SoundEffect';
import { loadAsset } from '../cyoa-manifest';

export default function Narrator() {
  const { step, emit, eventConfig, narrationStepConfig, narrationStepIndex, narrator, successAudio, failureAudio } = shared.story;
  const [lines, setLines] = useState<string[]>([]);
  const [success, setSuccess] = useState<boolean|undefined>(undefined);
  const [soundEffect, setSoundEffect] = useState<AudioConfig|undefined>(undefined);

  useEffect(() => {
    setSuccess(undefined);
    const text = narrationStepConfig?.text;
    if (text) {
      if (narrationStepIndex) {
        setLines(current => current.concat([text]));
      } else {
        setLines([text]);
      }
    } else if (narrationStepConfig) {
      setLines([]);
    }
  }, [narrationStepIndex, narrationStepConfig]);

  const [ opacity, setOpacity ] = useState(narrationStepConfig ? 1 : 0);
  useEffect(() => {
    if (!narrationStepConfig && !eventConfig?.narrate?.showNarrator) {
      setOpacity(0);
    } else if (narrationStepConfig && typeof(eventConfig?.narrate?.showNarrator) === 'boolean' && !eventConfig.narrate.showNarrator) {
      setOpacity(0);
    } else {
      setOpacity(1);
    }
  }, [narrationStepConfig, eventConfig]);

  const onClickStep = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!narrationStepConfig) { return; }
    if (narrationStepConfig?.clickEvent) { emit(narrationStepConfig.clickEvent); }
    if (narrationStepConfig.buttons || narrationStepConfig.choice) { return; }
    if (narrationStepConfig) { step(); }
  };

  useEffect(() => {
    if (typeof(success) === 'boolean') {
      setSoundEffect(success ? successAudio : failureAudio);
    } else {
      setSoundEffect(undefined);
    }
  }, [success, successAudio, failureAudio])

  const onChoose = (option: ChoiceOptionConfig) => {
    if (typeof(option.result.success) === 'boolean') {
      setSuccess(option.result.success);
    }
  };

  return <div className='Narrator' style={{ opacity }}>
    {narrator?.image && <img
      onClick={onClickStep}
      src={loadAsset(narrator.image)}
      alt="story narrator"
      className={['character', narrator?.position || ''].join(' ')}
      style={{ height: narrator?.height || '24%' }}
    ></img>}
    {
      narrationStepConfig && <div
        onClick={(e) => narrationStepConfig.buttons || onClickStep(e)}
        className={`bubble  ${success === false ? 'failure' : ''}`}
        style={{ bottom: narrator?.height || '26%' }}
        >
        {
          narrationStepConfig.choice
            ? <NarratorChoice choice={narrationStepConfig.choice} onChoose={onChoose}></NarratorChoice>
            : lines.map((line, i) => <p key={i}>{line}</p>)
        }
        {
          narrationStepConfig.buttons
            ? narrationStepConfig.buttons.map((button, i) => <div className='basic-button spaced' key={i} onClick={() => {emit(button.event)}}>{button.text}</div>)
            : narrationStepConfig && !narrationStepConfig.choice && <div className='basic-button' onClick={onClickStep}>→</div>
        }
        {soundEffect && <SoundEffect audio={soundEffect} onComplete={() => { setSoundEffect(undefined) }}/>}
      </div>
    }
  </div>
}