import { useCallback, useEffect, useRef } from "react";
import useTrig from "react-use-trig";
import { loadAsset } from "../cyoa-manifest";

export default function Typewriter(props: { lines: string[] }) {
  const parentRef = useRef<HTMLDivElement>(null);
  const { trig } = useTrig();
  const { lines } = props;

  const write = useCallback((line: number, character: number) => {
    if (!parentRef.current) { return; }
    let div = parentRef.current.children[line] as HTMLDivElement;
    const audio = new Audio();
    audio.src = loadAsset('audio/key.mp3');
    let onLoad = () => {
      const next = (line: number, character: number) => {
          if (line < lines.length) {
            trig({ t: !character ? 1500 : 200, f: () => write(line, character) });
          }
        }
      audio.currentTime = 0.7;
      audio.play();
      trig({ t: 600, f: () => {
        div.innerText = lines[line].substring(0, character);
      }})
      if (character === lines[line].length) {
        if (line < lines.length - 1) {
          next(line + 1, 0);
        }
      } else {
        next(line, character + 1);
      }
      audio.removeEventListener('loadeddata', onLoad);
    }
    audio.addEventListener('loadeddata', onLoad);
  }, [lines, trig]);

  useEffect(() => {
    trig({ t: 2000, f: () => write(0, 0) });
  }, [lines, write, trig]);

  return <div ref={parentRef}>
    {props.lines.map((_, i) => <div key={i}></div>)}
  </div>
}