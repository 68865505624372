import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sceneForPath, ScenePath } from "../engine/story";
import useStory, { shared } from "../engine/useStory";
import AdventureCard from "../quests/AdventureCard";
import Background from "./Background";
import BackgroundAudio from "./BackgroundAudio";
import Narrator from './Narrator';
import './Story.scss'

function NotFound() {
  return <div>404</div>
}

function SceneComponent(props: { path: ScenePath }): JSX.Element {
  const S = sceneForPath(props.path); 
  if (!S) { return <NotFound/> }
  return <S/>
}

interface StoryProps {
  children?: JSX.Element|JSX.Element[]|string;
}

export default function Story(props : StoryProps): JSX.Element {
  shared.story = useStory();
  const { start, emit, scenePath, openingScene } = shared.story;
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname !== '/') {
      const [ , scene, eventName ] = history.location.pathname.split('/');
      start('/' + scene);
      if (eventName) {
        emit(eventName, true);
        history.push('/' + scene);
      }
    } else {
      start(openingScene);
    }
  }, [emit, start, history, openingScene])
  return <>
    <BackgroundAudio></BackgroundAudio>
    <Background></Background>
    {scenePath && <SceneComponent path={scenePath}></SceneComponent>}
    <Narrator></Narrator>
  </>
}