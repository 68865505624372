import { useEffect, useRef } from "react";
import './Background.scss';

function starsBackground(canvas: HTMLCanvasElement, targetElement: HTMLElement) {
  const height = window.innerHeight * 2;
  const width = window.innerWidth * 2;
  canvas.height = height;
  canvas.style.height = height + 'px';
  canvas.width = width;
  canvas.style.width = width + 'px';

  var ctx = canvas.getContext('2d');
  if (!ctx) { return; }

  function smallStarCreate(ctx: CanvasRenderingContext2D, starNumber: number, starSize: number) {
    for (var i = 0; i < starNumber; i++) {
      ctx.beginPath();
      var starLeft = Math.floor(Math.random() * width) + 1;
      var starTop = Math.floor(Math.random() * height) + 1;
      var colorVal01 = Math.floor(Math.random() * 106) + 150;
      var colorVal02 = Math.floor(Math.random() * 106) + 150;
      var opacityVal = (Math.floor(Math.random() * 11)) / 10;
      ctx.fillStyle = "rgba(" + colorVal01 + ", " + colorVal02 + ", " + 255 + ", " + opacityVal + ")";
      ctx.fillRect(starLeft, starTop, starSize, starSize);
      ctx.fill();
    }
  }
  smallStarCreate(ctx, 400, 1);
  smallStarCreate(ctx, 100, 2);
  ctx.restore();
  ctx.save();

  function bigStarCreate(ctx: CanvasRenderingContext2D, starNumber: number, starSize: number) {
    for (var i = 0; i < starNumber; i++) {
      ctx.beginPath();
      var starLeft = Math.floor(Math.random() * width) + 1;
      var starTop = Math.floor(Math.random() * height) + 1;
      var colorVal01 = Math.floor(Math.random() * 106) + 150;
      var colorVal02 = Math.floor(Math.random() * 106) + 150;
      var opacityVal = (Math.floor(Math.random() * 11)) / 10;
      var radgrad = ctx.createRadialGradient(starLeft, starTop, 0, starLeft, starTop, starSize);
      radgrad.addColorStop(0, 'rgba(255, 255, 255, 1)');
      radgrad.addColorStop(1, "rgba(" + colorVal01 + ", " + colorVal02 + ", " + 255 + ", " + opacityVal + ")");
      ctx.fillStyle = radgrad;
      ctx.arc(starLeft, starTop, starSize, 0, Math.PI * 2, true);
      ctx.fill();
    }
  }
  bigStarCreate(ctx, 20, 1);
  bigStarCreate(ctx, 10, 2);
  var sky = new Image();
  sky.src = canvas.toDataURL("image/png");
  targetElement.style.background = "url(" + sky.src + ")";

  ctx.restore();
  ctx.save();
}


export default function Background() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (canvasRef.current && targetRef.current) {
      starsBackground(canvasRef.current, targetRef.current);
    }
  }, [])

  return <>
    <canvas
      ref={canvasRef} id='background'
      onDoubleClick={() => {}}
    ></canvas>
    <div className="background-target" ref={targetRef}>
    </div>
  </>
}

// function animateBackground(canvasRef: any) {
//   const canvas = canvasRef.current as HTMLCanvasElement;
//   const ctx = canvas.getContext('2d');
//   if (!ctx) { return; }
//   const stars: Star[] = []

//   var onHold = false;
//   var opacity = 0.1;
//   var yDisplace = 1.02;

//   function startOnHold() {
//     onHold = true
//   }
//   function endOnHold() {
//     onHold = false
//   }

//   // window.addEventListener("dblclick", function () {
//   //   yDisplace = (yDisplace == 1.001) ? 1.02 : 1.001
//   // })
//   // window.addEventListener("mousedown", function () { startOnHold() })
//   // window.addEventListener("mouseup", function () { endOnHold() })
//   // window.addEventListener("touchstart", function () { startOnHold() })
//   // window.addEventListener("touchend", function () { endOnHold() })
//   // window.addEventListener("resize", function () {
//   //   canvas.width = window.innerWidth;
//   //   canvas.height = window.innerHeight;
//   // })

//   class Star {
//     color = randomColorPicker();
//     speedX = 0;
//     speedY = 0;
//     blur = 0;
//     alpha = 0;
//     maxAlpha = 0.5;
//     lastSpeedX = 0;
//     lastSpeedY = 0;
//     first = true
//     constructor(public x: number, public y: number, public radius: number) {
//     }
//     update() {
//       const lastPoint = { x: this.x, y: this.y }
//       if (this.alpha < this.maxAlpha) { this.alpha += 0.01 }

//       if (onHold) {
//         if (this.speedX < 30) {
//           this.speedX *= 1.02
//           this.speedY *= yDisplace
//         }
//       }
//       if (!onHold && this.speedX > this.lastSpeedX) {
//         this.speedX /= 1.02
//         this.speedY /= yDisplace
//       }
//       this.x += this.speedX;
//       this.y += this.speedY;
//       if ((this.x + this.radius > window.innerWidth || this.x < 0) && (this.y + this.radius > window.innerHeight || this.y < 0)) {
//         this.reset()
//       }

//       if (ctx) {
//         ctx.beginPath()
//         ctx.strokeStyle = `rgba(${this.color}, ${this.alpha})`
//         ctx.lineWidth = 3
//         ctx.moveTo(lastPoint.x, lastPoint.y)
//         ctx.lineTo(this.x, this.y)
//         ctx.stroke()
//         ctx.closePath()
//       }

//     }
//     reset() {
//       var directionX = Math.random() >= 0.5 ? 1 : -1;
//       var directionY = Math.random() >= 0.5 ? 1 : -1;
//       this.speedX = directionX * Math.random();
//       this.speedY = directionY * Math.random();
//       this.lastSpeedX = this.speedX
//       this.lastSpeedY = this.speedY
//       var random = Math.random() //random displacement
//       this.x = window.innerWidth / 2 + this.speedX * random * window.innerWidth / 2
//       this.y = window.innerHeight / 2 + this.speedY * random * window.innerHeight / 2
//       this.alpha = 0
//       this.maxAlpha = Math.random()
//     }
//   }

//   for (var i = 0; i < 200; i++) {
//     var st = new Star(window.innerWidth / 2, window.innerHeight / 2, 2)
//     st.reset()
//     stars.push(st);
//   }

//   function randomColorPicker() {
//     var colors = ["195, 112, 255", "142, 102, 232", "129, 125, 255", "102, 132, 232", "112, 183, 255"]
//     return colors[Math.floor(Math.random() * colors.length)]
//   }

//   function animate() {
//     requestAnimationFrame(animate)
//     // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

//     if (onHold && opacity > 0.05) {
//       opacity -= 0.01
//     }
//     if (!onHold && opacity < 0.1) {
//       opacity += 0.01
//     }
//     if (ctx) {
//       ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`
//       ctx.fillRect(0, 0, canvas.width, canvas.height)
//     }
//     for (var i = 0; i < stars.length; i++) {
//       stars[i].update();
//     }
//   }

//   animate()

// }