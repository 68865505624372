import { useEffect } from "react";
import { AudioConfig } from "../engine/story";
import useTrig from "react-use-trig";
import { loadAsset } from "../cyoa-manifest";
import EventEmitter from "events";

const soundEffectStream = new EventEmitter();
export { soundEffectStream }

export default function SoundEffect(props: { audio: AudioConfig, onComplete: () => void}) {
  const { trig } = useTrig();
  const { audio, onComplete } = props;

  useEffect(() => {
    const element = new Audio();
    element.src = loadAsset(audio.file);
    let hasStarted = false;
    let isDone = false;
    let timeout: NodeJS.Timeout;
    const emitPause = () => {
      element.pause();
      soundEffectStream.emit('pause', { audio });
      isDone = true;
      onComplete();
      timeout && clearTimeout(timeout);
    }

    const onEnded = () => {
      emitPause();
    };
    const onLoadedData = () => {
      if (audio.stopTime) { 
        timeout = (trig({ t: (audio.stopTime - (audio.startTime || 0)) * 1000, f: () => {
          emitPause();
        }})[0]);
      }
      element.addEventListener('ended', onEnded);
      soundEffectStream.emit('play', { audio })
      hasStarted = true;
      element.volume = audio.volume || 0.5;
      element.currentTime = audio.startTime || 0;
      element.play();
    }
    element.addEventListener('loadeddata', onLoadedData);
    return () => {
      element.removeEventListener('loadeddata', onLoadedData);
      element.removeEventListener('ended', onEnded);
      if (hasStarted && !isDone) {
        emitPause();
      }
    };
  }, [audio, onComplete, trig])

  return <></>;
}