import { useEffect, useReducer, useState } from "react";
import { AudioConfig } from "../engine/story";
import { shared } from "../engine/useStory";
import TWEEN from '@tweenjs/tween.js';
import { loadAsset } from "../cyoa-manifest";
import { soundEffectStream } from '../special_effects/SoundEffect';

const DEFAULT_VOLUME = 0.5;

function crossfadeAudio(beginAudio: undefined|null|HTMLAudioElement, endAudio: undefined|null|HTMLAudioElement, options: {
  noFadeIn: boolean
  maxVolume: number
}={
  noFadeIn: false,
  maxVolume: DEFAULT_VOLUME
}) {
  if (beginAudio) {
    if (options.noFadeIn) {
      beginAudio.volume = options.maxVolume;
    } else {
      beginAudio.play();
      setVolume(beginAudio, { volume: options.maxVolume, fadeTime: 2000 });
    }
  }
  if (endAudio) {
    let audio = endAudio;
    setVolume(endAudio, { volume: 0, fadeTime: 2000 })
      .then(() => {
        // Make sure it's still assigned to the same file
        if (audio === endAudio) {
          endAudio.pause();
          endAudio.currentTime = 0;
        }
      });
  }
}

interface AudioReducerState {
  currentAudioElement?: HTMLAudioElement,
  lastAudioElement?: HTMLAudioElement,
  currentAudioConfig?: AudioConfig,
  lastAudioConfig?: AudioConfig,
}

function setVolume(element: HTMLAudioElement, options: {
  volume?: number,
  fadeTime?: number
} = {
 volume: DEFAULT_VOLUME,
 fadeTime: 0
}) {
  return new Promise((resolve) => {
    const volume = typeof(options.volume) === 'number' ? options.volume : DEFAULT_VOLUME;
    new TWEEN.Tween(element)
      .to({ volume }, options.fadeTime)
      .onComplete(resolve)
      .start();
  });
}

export default function BackgroundAudio() {
  const reducer = (state: AudioReducerState, action: { type: string, audio: AudioConfig }): AudioReducerState => {
    const newState = { ...state };
    switch (action.type) {
      case 'crossfade':
        const currentAudioFile = state.currentAudioConfig?.file
        if (currentAudioFile && (currentAudioFile === audio?.file)) {
          // skip the action if the same song is already playing
          break;
        }
        newState.lastAudioConfig = state.currentAudioConfig;
        newState.lastAudioElement = state.currentAudioElement;
        if (!action.audio.file) {
          newState.currentAudioConfig = undefined;
          newState.currentAudioElement = undefined;
        } else {
          newState.currentAudioConfig = action.audio;
          newState.currentAudioElement = new Audio();
          newState.currentAudioElement.src = loadAsset(action.audio.file);
          newState.currentAudioElement.autoplay = true;
          newState.currentAudioElement.loop = true;
          newState.currentAudioElement.currentTime = action.audio.startTime || 0;
          newState.currentAudioElement.volume = 0;
        }
        break;
    }
    return newState;
  }

  const { audio } = shared.story;
  const [soundEffectCount, setSoundEffectCount] = useState<number>(0);
  const [ state, dispatch ] = useReducer(reducer, {
    currentAudioElement: undefined,
    lastAudioElement: undefined,
    currentAudioConfig: undefined,
    lastAudioConfig: undefined,
  });

  useEffect(() => {
    if (soundEffectCount === 0) {
      if (state.currentAudioElement && state.currentAudioConfig) {
        setVolume(state.currentAudioElement, { volume: state.currentAudioConfig.volume, fadeTime: 100 });
      }
    } else if (soundEffectCount > 0) {
      state.currentAudioElement && setVolume(state.currentAudioElement, { volume: 0.2, fadeTime: 100 });
    }
  }, [soundEffectCount, state.currentAudioElement, state.currentAudioConfig])

  useEffect(() => {
    const onPlay = (a: AudioConfig) => { setSoundEffectCount(soundEffectCount + 1); };
    const onPause = (a: AudioConfig) => { setSoundEffectCount(soundEffectCount - 1); };
    soundEffectStream.on('play', onPlay);
    soundEffectStream.on('pause', onPause);
    return () => {
      soundEffectStream.off('play', onPlay);
      soundEffectStream.off('pause', onPause);
    }
  }, [soundEffectCount]);

  useEffect(() => {
    if(state.currentAudioElement || state.lastAudioElement) {
      crossfadeAudio(state.currentAudioElement, state.lastAudioElement);
    }
  }, [state]);

  useEffect(() => {
    if (audio) { dispatch({ type: 'crossfade', audio}); }
  }, [audio]);
 
  return <>
    {/* <audio id='background-audio-1' ref={audioElement1} autoPlay loop src={loadAsset(state.audioConfig1?.file)}></audio>
    <audio id='background-audio-2' ref={audioElement2} autoPlay loop src={loadAsset(state.audioConfig2?.file)}></audio> */}
  </>

}
