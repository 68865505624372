import { EditableCollectionType } from './editable.interface';
import './EntityError.scss'

function getErr(error: any) {
  return typeof error === 'string' ? { message: error } : error
}

export default function EntityError(props: {
  collectionType: EditableCollectionType,
  error?: any,
  onCreate: () => void
}) {

  const err = getErr(props.error)

  if (!err) {
    return null;
  }

  function subComponents() {
    if (err.status === 404) {
      return <section className="not-found">
        <div> does not yet exist</div>
        <button onClick={() => props.onCreate()}>create</button>
      </section>
    } else if (err.message) {
      return <div>{err.message}</div>
    } else if (typeof err === 'object') {
      return <div>{JSON.stringify(err)}</div>
    }
  }

  return <div className="error">
    {subComponents()}
  </div>
}