import { useEffect } from 'react';
import './Confetti.scss';
const anime:any = require('animejs/lib/anime.es').default;

export default function Confetti(props: { interactive: boolean, onClick: (e: React.MouseEvent) => void}) {
  useEffect(() => { setup(); }, []);
  useEffect(() => { setup(); }, [ props.interactive ]);
  return <canvas onClick={props.onClick} className="confetti"></canvas>
}

function setup() {
  var canvasEl = document.querySelector('.confetti') as HTMLCanvasElement;
  if (!canvasEl) { return; }
  var ctx = canvasEl.getContext('2d');
  if (!ctx) { return; }
  var numberOfParticules = 30;
  // var pointerX = 0;
  // var pointerY = 0;
  // var tap = ('ontouchstart' in window || navigator.msMaxTouchPoints) ? 'touchstart' : 'mousedown';
  var colors = ['#FF1461', '#18FF92', '#5A87FF', '#FBF38C'];

  function setCanvasSize() {
    canvasEl.width = window.innerWidth;
    canvasEl.height = window.innerHeight;
    canvasEl.style.width = window.innerWidth + 'px';
    canvasEl.style.height = window.innerHeight + 'px';
    canvasEl.style.opacity = '1';
  }

  // function updateCoords(e: any) {
  //   pointerX = e.clientX || e.touches[0].clientX;
  //   pointerY = e.clientY || e.touches[0].clientY;
  // }

  function setParticuleDirection(p: any) {
    var angle = anime.random(0, 360) * Math.PI / 180;
    var value = anime.random(50, 180);
    var radius = [-1, 1][anime.random(0, 1)] * value;
    return {
      x: p.x + radius * Math.cos(angle),
      y: p.y + radius * Math.sin(angle)
    }
  }

  function createParticle(x: number, y: number) {
    let p: any = {
      x: x,
      y: y,
      color: colors[anime.random(0, colors.length - 1)],
      radius: anime.random(16, 32),
    }
    p.endPos = setParticuleDirection(p);
    p.draw = () => {
      if (!ctx) { return; }
      ctx.beginPath();
      // ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
      ctx.fillRect(p.x, p.y, p.radius, p.radius);
      ctx.fillStyle = p.color;
      ctx.fill();
    }
    return p;
  }

  function createCircle(x: number, y: number) {
    var p: any = {
      x: x,
      y: y,
      color: '#FFF',
      radius: 0.1,
      alpha: .5,
      lineWidth: 6,
    };
    p.draw = function() {
      if (!ctx) { return; }
      ctx.globalAlpha = p.alpha;
      ctx.beginPath();
      ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
      ctx.lineWidth = p.lineWidth;
      ctx.strokeStyle = p.color;
      ctx.stroke();
      ctx.globalAlpha = 1;
    }
    return p;
  }

  function renderParticle(anim: anime.AnimeInstance) {
    for (var i = 0; i < anim.animatables.length; i++) {
      (anim.animatables[i] as any).target.draw();
    }
  }

  function animateParticles(x: number, y: number) {
    var circle = createCircle(x, y);
    var particles = [];
    for (var i = 0; i < numberOfParticules; i++) {
      particles.push(createParticle(x, y));
    }
    anime.timeline()
      .add({
        targets: particles,
        x: (p: any) => { return p.endPos.x; },
        y: (p: any) => { return p.endPos.y; },
        radius: 0.1,
        duration: anime.random(1200, 1800),
        easing: 'easeOutExpo',
        update: renderParticle
      })
      .add({
        targets: circle,
        radius: anime.random(80, 160),
        lineWidth: 0,
        alpha: {
          value: 0,
          easing: 'linear',
          duration: anime.random(600, 800),  
        },
        duration: anime.random(1200, 1800),
        easing: 'easeOutExpo',
        update: renderParticle,
        offset: 0
      });
  }

  // let render = anime({
  anime({
    duration: Infinity,
    update: () => {
      if (!ctx) { return; }
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    }
  });

  // document.addEventListener(tap, function(e: any) {
  //   render.play();
  //   // updateCoords(e);
  //   animateParticles(pointerX, pointerY);
  // }, false);

  var centerX = window.innerWidth / 2;
  var centerY = window.innerHeight / 2;

  function autoClick() {
    animateParticles(
      anime.random(centerX-50, centerX+50), 
      anime.random(centerY-50, centerY+50)
    );
    anime({duration: 500}).finished.then(autoClick);
  }

  autoClick();
  setCanvasSize();
}