import { useEffect, useState } from 'react'

const dataLens = (o: { data: any }) => o.data
const errorLens = (o: { error: any }) => o.error

export function useFetch (initialUrl: string, initialParams = {}, skip = false) {
  const [url, updateUrl] = useState(initialUrl)
  const [params, updateParams] = useState<Record<string, string>>(initialParams)
  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState<any>(null)
  const [refetchIndex, setRefetchIndex] = useState(0)

  const queryString = Object.keys(params)
    .map((key) => encodeURIComponent(key) + '=' +
    encodeURIComponent(params[key])).join('&')

  const refetch = () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1)

  useEffect(() => {
    const fetchData = async () => {
      if (skip) return
      setIsLoading(true)
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}${queryString}`, {
          headers: {
            'Authorization': `bearer ${process.env.REACT_APP_API_TOKEN}`
          }
        })
        const result = await response.json()
        if (response.ok) {
          setData(dataLens(result))
          setHasError(false)
        } else {
          setHasError(true)
          setError(errorLens(result))
        }
      } catch (err: any) {
        setHasError(true)
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [url, queryString, refetchIndex])

  return { data, isLoading, hasError, error, updateUrl, updateParams, refetch }
}

export function useRequest(url: string, refresher: () => void) {
  const [data, setData] = useState<Record<string, any>|null>(null)
  const [method, setMethod] = useState<string|undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState<any>(null)
  const [refetchIndex, setRefetchIndex] = useState(0)
  const [result, setResult] = useState<any>(null)

  const send = () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1)

  useEffect(() => {
    const fetchData = async () => {
      if (!method) { return }
      setIsLoading(true)
      try {
        const path = method === 'POST' ? url.split('/').slice(0, -1).join('/') : url
        const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`,
          {
            method,
            body: JSON.stringify(data),
            headers: {
              'Authorization': `bearer ${process.env.REACT_APP_API_TOKEN}`,
              'Content-Type': 'application/json',
            }
          }
        )
        const result = {
          status: response.status,
          statusText: response.statusText,
          body:  await response.json()
        }
        if (response.ok) {
          setResult(result)
          setHasError(false)
        } else {
          setHasError(true)
          setError(result)
        }
      } catch (err: any) {
        setHasError(true)
        setError({ body: err.message })
      } finally {
        setIsLoading(false)
        refresher()
      }
    }
    fetchData()
  }, [refetchIndex])

  return {result, isLoading, hasError, error,
    post: (body: Record<string, any>) => (setData(body), setMethod('POST'), send()),
    put: (body: Record<string, any>) => (setData(body), setMethod('PUT'), send()),
    patch: (body: Record<string, any>) => (setData(body), setMethod('PATCH'), send()),
    delete: (body: Record<string, any>) => (setData(body), setMethod('DELETE'), send()),
  }
}