import { useEffect, useRef, useState } from "react";
import { loadAsset, QuestProps } from "../cyoa-manifest";
import SoundEffect from "../special_effects/SoundEffect";
import Quest from "../components/Quest";
import './pizza.scss'
import useTrig from 'react-use-trig';

export default function PizzaQuest(props: QuestProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const data = props.quest.data as {
    prompts: string[];
    equation: string;
    label: string;
    answer: number;
  };
  const [success, setSuccess] = useState<boolean>(false);
  const audioFile = 'audio/groan.mp3';
  const [sound, setSound] = useState<string|undefined>(audioFile);
  const { trig } = useTrig();

  const random = () => (Math.random() * 2000) + 3000;

  useEffect(() => {
    trig({ t: random(), f: () => setSound(sound ? undefined : audioFile) });
  }, [sound, trig]);

  useEffect(() => { inputRef.current?.focus(); }, []);


  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const value = inputRef.current?.value;
    if (Number(value) === data.answer) {
      if (inputRef.current) { inputRef.current.readOnly = true; }
      setSuccess(true);
    }
    return false;
  }

  return data
    ? <Quest success={success} quest={props.quest}>
      <div className="PizzaQuest" style={{ backgroundImage: `url(${loadAsset('img/pizza.jpg')})`}}>
        <div className="container">
          <div className="prompts">
            {data.prompts.map((p, i) => <div key={i}>{p}</div>)}
          </div>
          <form className='equation' onSubmit={onSubmit}>
            <div>{data.equation}</div>
            <label>
              {data.label}
              <input type="number" ref={inputRef}></input>
              {!success && <input type="submit" className="basic-button" value="Submit"></input>}
            </label>
          </form>
          {success && <div className="success">That sounds about right.</div>}
        </div>
        {sound && <SoundEffect audio={{ file: sound }} onComplete={() => {}}></SoundEffect>}
      </div>
    </Quest>
    : <></>;
}