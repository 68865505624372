
import { useEffect, useRef } from 'react';
import './forest.scss';

export interface ForestProps {
}

interface ForestOptions {
  leafColors: string[];
  branchColors: string[];
  animationFrameRate: number;
  treeCount: number;
  depthRange: [number, number];
  branchLengthRange: [number, number];
  leafSizeRange: [number, number];
  branchThickness: number;
  yDisplacementRange: [number, number];
  xDisplacementRange: [number, number];
  trunkAngleRange: [number, number];
  branchAngleRange: [number, number];
}

/**
 * Primary UI component for user interaction
 */
export default function Forest(props: ForestProps): JSX.Element {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      draw(canvasRef.current, {
        animationFrameRate: 5,
        treeCount: 300,
        depthRange: [4, 7],
        branchLengthRange: [15, 25],
        leafSizeRange: [15, 20],
        branchThickness: 1,
        yDisplacementRange: [0, 700],
        xDisplacementRange: [-10, 10],
        trunkAngleRange: [-85, -95],
        branchAngleRange: [15, 40],
        branchColors: [
          '#1e1302',
          '#281903',
          '#322004',
          '#3b2605',
          '#452c06',
          '#4f3206',
          '#593907',
          '#735221',
        ],
        leafColors: ['#29AB87', '#00755E', '#3B7A57', '#004B49', '#1C352D', '#1A2421',
          '#45841b',
          '#4e971e',
          '#58aa22',
          '#72c43c',
          '#81ca51',
          '#91d167',
          '#a1d77d',
          '#b1de93',
          '#c0e5a8',
          '#d0ebbe',

          //blues
          '#135f58',
          '#17716a',
          '#1b847b',
          '#1e978d',
          '#22aa9e',
          '#3cc4b8',
          '#51cac0',
          '#67d1c8',
      ],
      });
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className={['forest-canvas'].join(' ')}
    >
    </canvas>
  );
};


function draw(canvas: HTMLCanvasElement, options: ForestOptions){
  const context = canvas.getContext('2d');
  if (!context) { return; }
  const h = window.innerHeight * 3;
  const w = window.innerWidth < 768 ? 2000 : window.innerWidth * 2;
  canvas.height = h
  canvas.width = w;
  canvas.style.height = h + 'px';
  canvas.style.width = w + 'px';
  requestAnimationFrame(() => drawTree(context, w, h, options));
}


function drawTree(context: CanvasRenderingContext2D, width: number, height: number, options: ForestOptions){
  let count = options.treeCount;
  for (let i = 0; i < count; i++) {
    const x = (width/count)*i + random(...options.xDisplacementRange);
    const y = (x < width/2 ? height - x : height - (width - x)) + random(...options.yDisplacementRange) - 200;
    branch(context, x, y, random(...options.trunkAngleRange), random(...options.depthRange), options);
  }
}
function branch(context: CanvasRenderingContext2D, x1: number, y1: number, angle: number, depth: number, options: ForestOptions) {
  const branchArmLength = random(...options.branchLengthRange);
  if (depth !== 0) {
    let x2 = x1 + (Math.cos(angle * (Math.PI / 180.0)) * depth * branchArmLength);
    let y2 = y1 + (Math.sin(angle * (Math.PI / 180.0)) * depth * branchArmLength);
    setTimeout(() => requestAnimationFrame(() => {
      line(context, x1, y1, x2, y2, depth * options.branchThickness, options);
      branch(context, x2, y2, angle - random(...options.branchAngleRange), depth - 1, options);
      branch(context, x2, y2, angle + random(...options.branchAngleRange), depth - 1, options);
    }), 1000 / options.animationFrameRate);
  }
  else {
    // setTimeout(() => requestAnimationFrame(() => {
    let x2 = x1 + (Math.cos(angle * (Math.PI / 180.0)) * depth * branchArmLength);
    let y2 = y1 + (Math.sin(angle * (Math.PI / 180.0)) * depth * branchArmLength);
    context.fillStyle = pick(options.leafColors);
    context.globalAlpha = 0.5;
    // context.arc(x2, y2, random(0, 10), 0, 2 * Math.PI, false);
    const size= random(...options.leafSizeRange);
    context.fillRect(x2 - size/2, y2 - size/2, size, size);
    context.fill();
    context.globalAlpha = 1;
    // }), 1000 / options.animationFrameRate);
  }
}
function line(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, thickness: number, options: ForestOptions) {
  context.strokeStyle = pick(options.branchColors);
  context.lineWidth = thickness * 1.5;
  context.beginPath();
  context.moveTo(x1,y1);
  context.lineTo(x2, y2);
  context.closePath();
  context.stroke();
}
function random(min: number, max: number) {
  return min + Math.floor(Math.random()*(max+1-min));
}
function pick(items: any[]) {
  const n = random(0, items.length - 1);
  return items[n];
}