import { useState } from "react";
import './Die.scss';

// Thanks to https://codepen.io/kriswilliams/pen/ZaEowM
export default function Die(props: { index: number, onRoll: (value: number) => void, locked?: boolean }) {
  const random = () => {
    return Math.floor(Math.random() * sides) + 1;
  }
  const sides = 6;
  const [roll, setRoll] = useState<number>(6);
  const [sameRoll, setSameRoll] = useState<boolean>(false);

  const onClick = (e: React.MouseEvent) => {
    if (props.locked) { return; }
    const value = random();
    setSameRoll(value === roll);
    setRoll(value);
    props.onRoll(value);
  };

  return <div className={`die ${props.locked ? 'locked' : ''} ${sameRoll ? 'same' : ''}`} onClick={onClick}>
    <div className={`die-inner`} data-roll={props.locked ? '1' : String(roll)}>
      {Array(sides).fill(0).map((_,i) => <div key={i} className='die-side'/>)}
    </div>
  </div>
}