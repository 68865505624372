import { useCallback, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Story from './components/Story';
import { preloadAssets } from "./engine/useStory";
import './App.scss';
import { Knot } from './util/Knot';
import useTrig from 'react-use-trig';
import Creator from './creator/Creator';

function App() {
  const [progress, setProgress] = useState<{total: number, current: number}>({current: 0, total: 100});
  const [canClick, setCanClick] = useState<boolean>(true);
  const [tokenInserted, setTokenInserted] = useState<boolean>(false);
  const [hasLoadded, setHasLoaded] = useState<boolean>(false);
  const { trig } = useTrig();
  const onProgress = useCallback((p: {total: number, current: number}) => {
    setProgress(p);
    if (p.current === p.total) {
      trig({ f: () => setHasLoaded(true), t: 500 });
    }
  }, [trig]);

  let percent = Number(progress && (progress.current / progress.total) * 100);
  const onClick = () => {
    if (!canClick) { return; }
    setCanClick(false)
    setTokenInserted(true);
    preloadAssets(onProgress);
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/create">
          <Creator />
        </Route>
        <Route path="*">
          {!hasLoadded
            ? <div className="App-cover"
              style={{color: 'white', height: '100vh', width: '100vw', background: '#333', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              onClick={onClick} onKeyDown={() => setHasLoaded(true)}
              >
              {Knot({
                _: !canClick,
                names: [],
                x: p => <div {...p}>
                  Loading... {percent.toFixed(0) + '%'}
                  <div style={{ background: 'white', width: (100 * 2) + 'px', height: '2px' }}>
                  </div>
                  <div style={{ background: 'cyan', width: (percent * 2) + 'px', height: '20px' }}>
                  </div>
                </div>
              })}
              {Knot({
                _: true,
                names: ['arcade'],
                insert: tokenInserted,
                x: p => <div {...p}>
                  <div style={{marginTop: '50px', height: '80px'}}>Insert (1) Token</div>
                  <div className="token" style={{width: '54px', height: '54px', background: 'goldenrod', border: '12px dashed darkgoldenrod', borderRadius: '40px'}}></div>
                  <div style={{width: '110px', height: '4px', marginTop: '20px', background: '#111', border: '6px solid #555', borderBottom: 'none', borderTopLeftRadius: '40px', borderTopRightRadius: '40px'}}></div>
                  <div style={{position: 'relative', zIndex: 5, width: '110px', height: '4px', background: '#111', border: '6px solid #555', borderTop: 'none', borderBottomLeftRadius: '40px', borderBottomRightRadius: '40px'}}></div>
                  <div style={{position: 'relative', zIndex: 5, height: '100px', width: '100px', background: '#333'}}></div>
                </div>
              })}
            </div>
            : <div className='App play'>
              <Story />
            </div>
          }
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
