
import React, { useEffect, useRef } from 'react';
import './tree.scss';

export interface TreeProps {
}

/**
 * Primary UI component for user interaction
 */
export const Tree: React.FC<TreeProps> = ({
  ...props
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      animateTree(canvasRef.current);
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className={['forest-canvas'].join(' ')}
      // style={{ backgroundColor: 'green' }}
      {...props}
    >
    </canvas>
  );
};


function animateTree(canvas: HTMLCanvasElement) {
	var height = window.innerHeight;
	var width = window.innerWidth;
	canvas.width = width;
	canvas.height = height;
	canvas.style.height = '100%';
	canvas.style.width = '100%';

	var ctx = canvas.getContext('2d');
  if (!ctx) { return; }

	var minsize = 10;
	var angle_diff = Math.PI/6;
	var stroke_len = 2;
	ctx.fillStyle = "#FF057A"
	ctx.fillRect(0,0,width,height);
	ctx.strokeStyle = 'white';


	function drawTree(ctx: CanvasRenderingContext2D, size: number,x: number,y: number,angle: number,minsize: number,stroke_len: number,level: number){
		ctx.save();
		ctx.translate(x,y);
		ctx.beginPath();
		ctx.lineTo(0,0);
		ctx.rotate(angle);
		ctx.lineTo(0,-size);
		ctx.closePath();
		//ctx.arc(0,0,1,0,2*Math.PI);
		ctx.lineWidth = stroke_len;
		ctx.stroke();
		ctx.restore();

		if(size>minsize){
			//right branch
			if(Math.random()>.1||level<1)
			drawTree(ctx, 3*size/4,x+size*Math.sin(angle),y-size*Math.cos(angle),angle+angle_diff,minsize,stroke_len*.99,level+1);
			//left Branch
			if(Math.random()>.1||level<1)
			drawTree(ctx, 3*size/4,x+size*Math.sin(angle),y-size*Math.cos(angle),angle-angle_diff,minsize,stroke_len*.99,level+1);

		}
	}


	//drawTree(height/4,width/2,height,0,minsize,stroke_len);
	var counter = 0;

	function animate(ctx: CanvasRenderingContext2D){
    if(counter % 100 === 0){
      for (let i = 0 ; i < 20; i++) {
        ctx.clearRect(0,0,width,height);
        ctx.fillRect(0,0,width,height);
        drawTree(ctx, height/4,width/2 + 10*i,height,0,minsize,stroke_len,0);
      }
      counter=1;
    }
    counter++;
		// window.requestAnimationFrame(() => animate(ctx));
		
	}
	animate(ctx);

}

// function drawTree(canvas: HTMLCanvasElement) {
//   var ctx = canvas.getContext("2d");
//   console.log(ctx);
//   if (!ctx) { return; }
//   function drawTree(ctx: CanvasRenderingContext2D, i: number) {
//     function draw(startX: number, startY: number, len: number, angle: number, branchWidth: number) {
//       ctx.lineWidth = branchWidth;

//       ctx.beginPath();
//       ctx.save();

//       ctx.strokeStyle = "green";
//       ctx.fillStyle = "green";

//       ctx.translate(startX, startY);
//       ctx.rotate(angle * Math.PI / 180);
//       ctx.moveTo(0, 0);
//       ctx.lineTo(0, -len);
//       ctx.stroke();

//       ctx.shadowBlur = 15;
//       ctx.shadowColor = "rgba(0,0,0,0.8)";

//       if (len < 8) {
//         ctx.restore();
//         return;
//       }

//       const r = (n: number) => {
//         return (Math.random() - 0.5) * n;
//       }

//       draw((0), -len, len * (0.8), angle - (120), branchWidth * (0.8));
//       // draw((0), -len, len*(0.8), angle+(120), branchWidth*(0.8));
//       // draw(0, -len, len*0.7, angle+15, branchWidth*0.8);

//       ctx.restore();
//     }
//     draw(400 + (80 * i), 600, 120, 0, 10);

//   }
//   function paint() {
//     canvas.height = window.innerHeight;
//     canvas.style.height = window.innerHeight + 'px';
//     canvas.width = window.innerWidth;
//     canvas.style.width = window.innerWidth + 'px';
//     for (let i = 0; i < 1; i++) {
//       drawTree(i);
//     }
//   }
//   paint();
// }