import { shared } from "../engine/useStory"
import Scene from '../components/Scene';
import Logo from '../stories/Logo';
import './opening-crawl.scss';

export default function OpeningCrawlScene() {
  const { sceneConfig } = shared.story;
  return <Scene>
    <div className="crawl">
      <div className="animation">
        <section className="intro" dangerouslySetInnerHTML={{
          __html: sceneConfig?.data?.intro || ''
        }}>
        </section>
        <section className="titles">
          <div>
            {sceneConfig?.data?.crawl.paragraphs.map((p: string, i: number) => <p key={i}>{p}</p>)}
          </div>
        </section>
        <section className="logo">
          <Logo></Logo>
        </section>
      </div>
    </div>
  </Scene>

}