import EventEmitter from 'events';
import storyConfig from '../data/cyoa.json';
import { sceneMap, questMap, QuestProps, loadAsset } from '../cyoa-manifest';
import AdventureCard from '../quests/AdventureCard';

export interface StoryState {
  story: StoryContext;
}

export interface StoryContextState {
  scenePath: ScenePath;
  eventKey: EventKey;
  allowEvents: string[];
  sceneConfig: SceneConfig;
  eventConfig: EventConfig;
  narrator?: CharacterConfig;
  narrationStepIndex: number;
  narrationStepConfig?: NarrationStepConfig;
  audio?: AudioConfig;
  timers: Record<TimerKey, any>;
}


export default class StoryContext {

  private static _instance = new StoryContext(storyConfig as StoryConfig);
  static get instance() {
    return StoryContext._instance;
  }

  context: StoryContextState = {
    scenePath: '',
    sceneConfig: {},
    eventKey: '',
    eventConfig: {},
    allowEvents: [],
    narrationStepIndex: 0,
    timers: {},
  };

  stream: EventEmitter = new EventEmitter();

  constructor(private config: StoryConfig) {
  }

  static preloadAssets(onProgress: (p: { current: number, total: number }) => void) {
    let i = 0;
    const emitProgress = () => {
      onProgress({
        current: i++,
        total: storyConfig.preload.img.length + storyConfig.preload.audio.length,
      });
    };
    emitProgress();
    for (let imageFile of storyConfig.preload.img) {
      const asset = loadAsset(imageFile);
      const image = new Image();
      const onLoad = () => {
        emitProgress();
        image.removeEventListener('load', onLoad);
      }
      const onError = (e: any) => {
        console.warn('problem');
        console.error(e);
        image.removeEventListener('error', onError);
      }
      image.addEventListener('load', onLoad);
      image.addEventListener('error', onError);
      image.src = asset;
    }
    for (let audioFile of storyConfig.preload.audio) {
      const asset = loadAsset(audioFile);
      const audio = new Audio()
      const onLoadedData = () => {
        emitProgress();
        audio.removeEventListener('loadeddata', onLoadedData);
      }
      const onError = (e: any) => {
        console.warn('problem');
        console.error(e);
        audio.removeEventListener('error', onError);
      }
      audio.addEventListener('loadeddata', onLoadedData);
      audio.addEventListener('error', onError);
      audio.src = asset;
    }
  }

  start(path: ScenePath) {
    this.setCurrentScene(path);
  }

  setCurrentScene(path: ScenePath) {
    Object.values(this.context.timers).forEach(timer => {
      clearTimeout(timer);
    });
    this.context.timers = {};
    this.context.scenePath = path;
    this.context.sceneConfig = this.currentSceneConfig;
    this.context.allowEvents = [];
    if (this.context.sceneConfig.init) {
      this.setCurrentEvent(this.context.sceneConfig.init);
    }
    this.context.narrator = this.currentNarrator;
    this.stream.emit('scene', this.context);
  }

  private setCurrentEvent(key: EventKey) {
    this.context.eventKey = key;
    this.context.eventConfig = this.currentEventConfig;
    if (!this.context.eventConfig) { throw new Error(`No EventConfig found for event "${key}" in scene "${this.context.scenePath}"` )};
    if (this.context.eventConfig.audio) {
      this.context.audio = this.context.eventConfig.audio;
    }
    this.addAllowedEvents(this.context.eventConfig.allowEvents);
    this.setNarrationStep(0);
    if (this.context.eventConfig.timers) {
      this.context.eventConfig.timers.forEach(timer => {
        this.context.timers[timer.key] = setTimeout(() => {
          this.produce(timer.event);
        }, timer.timeout);
      })
    }
    if (this.context.eventConfig.event) {
      this.produce(this.context.eventConfig.event);
    }
    if (this.context.eventConfig.advance) {
      this.setCurrentScene(this.context.eventConfig.advance.path);
    }
    this.context.narrator = this.currentNarrator;
    this.stream.emit('event', this.context);
  }

  private setNarrationStep(index: number) {
    this.context.narrationStepIndex = index;
    this.context.narrationStepConfig = this.currentNarrationStep;
    if (this.context.narrationStepConfig) {
      this.addAllowedEvents(this.context.narrationStepConfig.allowEvents);
    }
    this.stream.emit('step', this.context);
  }

  private addAllowedEvents(events?: EventKey[]) {
    if (events) {
      this.context.allowEvents.push(...events);
    };
  }

  private sceneConfig(path: ScenePath): SceneConfig {
    const sceneConfig = this.config.scenes[path];
    if (!sceneConfig) { throw new Error('No SceneConfig found for scene "' + path + '"'); }
    return sceneConfig;
  }

  private eventConfig(key: EventKey): EventConfig {
    const sceneConfig = this.currentSceneConfig;
    if (!sceneConfig) { throw new Error(`There is no current scene context to associate with event "${key}"`)}
    const events = sceneConfig.events;
    if (!events || !events[key]) { throw new Error(`No EventConfig found for event "${key}" in scene "${this.context.scenePath}"` )};
    return events[key];
  }

  get openingScene(): ScenePath {
    return this.config.openingScene;
  }

  get successAudio(): AudioConfig|undefined {
    return this.config.defaultSuccessAudio;
  }

  get failureAudio(): AudioConfig|undefined {
    return this.config.defaultFailureAudio;
  }

  get currentSceneConfig(): SceneConfig {
    const { scenePath } = this.context;
    return this.sceneConfig(scenePath);
  }

  get currentEventConfig(): EventConfig {
    const { eventKey } = this.context;
    return this.eventConfig(eventKey);
  }

  get currentNarrationStep(): NarrationStepConfig|undefined {
    const script = this.currentEventConfig.narrate?.script;
    if (!script) { return undefined; }
    const entry = script[this.context.narrationStepIndex]
    return entry;
  }

  get currentNarrator(): CharacterConfig {
    const narratorConfig = (() => {
      const eventConfig = this.currentEventConfig;
      if (eventConfig && eventConfig.narrate?.narrator) {
        return eventConfig.narrate.narrator;
      }
      const sceneConfig = this.currentSceneConfig;
      if (sceneConfig && sceneConfig.narrator) {
        return sceneConfig.narrator;
      }
      return this.config.defaultNarrator;
    })();
    return this.config.characters[narratorConfig.character];
  }

  get scenePaths(): ScenePath[] {
    return Object.keys(this.config.scenes);
  }

  produce(key: EventKey, force: boolean = false) {
    if (force || this.context.allowEvents.includes(key)) {
      console.log(`event "${key}": allowed${force ? ' by force' : ''}`);
      !force && this.context.allowEvents.splice(this.context.allowEvents.indexOf(key), 1);
      this.setCurrentEvent(key);
    } else {
      console.log(`event "${key}": not allowed`);
    }
  }

  step() {
    this.setNarrationStep(this.context.narrationStepIndex + 1);
  }
}

export function sceneForPath(path: ScenePath): () => JSX.Element {
  const sceneComponentName = path.replace('/', '')
    .split('-')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .concat(['Scene'])
    .join('')
  return sceneMap[sceneComponentName];
}

export function questForKey(key: QuestKey|string): (props: QuestProps) => JSX.Element {
  const questComponentName = key
    .split('_')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .concat(['Quest'])
    .join('')
  if (questComponentName in questMap) {
    return questMap[questComponentName];
  }

  return AdventureCard
}


export type EventKey = string;
export type CharacterKey = string;
export type DataKey = string;
export type ScenePath = string;
export type FilePath = string;
export type Milliseconds = number;
export type TimerKey = string;
export type QuestKey = string;

export interface ChoiceOptionConfig {
  text: string;
  result: {
    success?: boolean,
    text: string;
    event: EventKey;
  };
}

export interface ChoiceConfig {
  prompt?: string;
  options: ChoiceOptionConfig[];
}

export interface ButtonConfig {
  text: string;
  event: EventKey;
}

export interface NarrationStepConfig {
  text?: string;
  allowEvents?: EventKey[];
  choice?: ChoiceConfig;
  clickEvent?: EventKey;
  buttons?: ButtonConfig[];
}

export interface NarrationConfig {
  narrator?: NarratorConfig;
  script?: NarrationStepConfig[];
  showNarrator?: boolean;
}

export interface NarratorConfig {
  character: CharacterKey;
}

export interface AdvanceConfig {
  path: ScenePath;
}

export interface AudioConfig {
  file: FilePath;
  startTime?: number;
  stopTime?: number;
  volume?: number;
}

export interface QuestConfig {
  key: QuestKey;
  successEvent: EventKey;
  successAudio?: AudioConfig;
  data?: Record<DataKey, any>;
  card?: string;
}

export interface EventConfig {
  allowEvents?: EventKey[];
  narrate?: NarrationConfig;
  advance?: AdvanceConfig;
  audio?: AudioConfig;
  event?: EventKey;
  timers?: TimerConfig[];
  data?: Record<DataKey, any>;
  quest?: QuestConfig;
}

export interface TimerConfig {
  key: TimerKey;
  timeout: Milliseconds;
  event: EventKey;
}

export interface SceneConfig {
  description?: string;
  init?: EventKey;
  events?: Record<EventKey, EventConfig>;
  data?: Record<DataKey, any>;
  narrator?: NarratorConfig;
}

export interface CharacterConfig {
  image: FilePath;
  position?: 'right';
  height?: string;
}

export interface StoryConfig {
  title: string;
  characters: Record<CharacterKey, CharacterConfig>;
  scenes: Record<ScenePath, SceneConfig>;
  openingScene: ScenePath;
  defaultNarrator: NarratorConfig;
  defaultSuccessAudio?: AudioConfig;
  defaultFailureAudio?: AudioConfig;
}
