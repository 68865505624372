import hljs from 'highlight.js/lib/common';

import { useEffect } from "react"

export default function HL(props: {
  code: string,
  lang: string,
}) {
  useEffect(() => {
    hljs.highlightAll();
  }, [props.code])
  return <pre>
    <code
      className={`language-${props.lang}`}
      style={{background: 'transparent', padding: 0 }}
    >
      {props.code}
    </code>
  </pre>
}