import { useEffect, useRef, useState } from "react";
import { loadAsset, QuestProps } from "../cyoa-manifest";
import Quest from '../components/Quest';
import './mr_bucket.scss'

export default function MrBucketQuest(props: QuestProps) {
  const data = props.quest.data as { image: string, prompts: string[] };
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRef2 = useRef<HTMLCanvasElement>(null);
  const scrubberRef = useRef<HTMLDivElement>(null);
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [smokeOpacity, setSmokeOpacity] = useState<number>(1);
  const [clear, setClear] = useState<boolean>(false);
  const [touchLocation, setTouchLocation] = useState({x: -1, y: -1})
  const scrubberSize = 80;
  useEffect(() => {
    const interval1 = canvasRef.current && drawSmoke(canvasRef.current);
    const interval2 = canvasRef2.current && drawSmoke(canvasRef2.current);
    return () => { interval1 && clearInterval(interval1); interval2 && clearInterval(interval2) };
  }, [])
  const onTouchMove = (e: React.TouchEvent) => {
    const touch = e.changedTouches[0];
    if (touchLocation.x >= 0 && touchLocation.y >= 0) {
      const touchscreenHandicap = 2;
      onScrub({x: touch.clientX, y: touch.clientY, dx: (touchLocation.x - touch.clientX) * touchscreenHandicap, dy: (touchLocation.y - touch.clientY) * touchscreenHandicap })
    }
    setTouchLocation({ x: touch.clientX, y: touch.clientY })
  }
  const onMouseMove = (e: React.MouseEvent) => {
    onScrub({ x: e.clientX, y: e.clientY, dx: e.movementX, dy: e.movementY });
  }
  const onScrub = (coords: {x: number, y: number, dx: number, dy: number}) => {
    if (!mouseDown) {
      if (scrubberRef.current) { scrubberRef.current.style.left = '-200px'; }
      return;
    }
    if (scrubberRef.current) {
      scrubberRef.current.style.left = (coords.x - scrubberSize/2) + 'px';
      scrubberRef.current.style.top = (coords.y - scrubberSize/2) + 'px';
    }
    setSmokeOpacity(smokeOpacity - (0.00003 * (Math.abs(coords.dx) + Math.abs(coords.dy))));
    if (smokeOpacity < 0.4) {
      setClear(true);
    }
  };
  return <Quest success={clear} quest={props.quest}>
    <div className="MrBucketQuest">
      <img
        className="hidden-character"
        src={loadAsset(data.image)}
        alt="character hidden behind smoke"
      ></img>
      <div className="opacity-layer" style={{ opacity: clear ? 0.1 : smokeOpacity }}></div>
      <div className="canvas-layer" style={{ opacity: clear ? 0.1 : 0.9 }}>
        <canvas ref={canvasRef} id="smoke-canvas-1" className="smoke-canvas" style={{ opacity: clear ? 0 : smokeOpacity }}></canvas>
        <canvas ref={canvasRef2} id="smoke-canvas-2" className="smoke-canvas"
          onMouseDown={() => setMouseDown(true)}
          onMouseLeave={() => setMouseDown(false)}
          onMouseUp={() => setMouseDown(false)}
          onMouseMove={onMouseMove}
          onTouchMove={onTouchMove}
          onTouchCancel={() => setMouseDown(false)}
          onTouchStart={() => setMouseDown(true)}
          onTouchEnd={() => setMouseDown(false)}
          style={{opacity: smokeOpacity, cursor: mouseDown ? 'grabbing' : 'grab' }}
          >
        </canvas>
      </div>
      <div className="scrubber" style={{ height: scrubberSize + 'px', width: scrubberSize + 'px'}} ref={scrubberRef}></div>
      { !clear && <div className="prompt">
        {data.prompts.map((p, i) => <div key={i}>{p}</div>)}
      </div> }
    </div>
  </Quest>
}

function drawSmoke(canvas: HTMLCanvasElement) {
  const particles: any[] = [];
  const particleCount = 40;
  // The maximum velocity in each direction
  const maxVelocity = 1;
  // The target frames per second (how often do we want to update / redraw the scene)
  const targetFPS = 10;
  // Set the dimensions of the canvas as variables so they can be used.
  const canvasWidth = canvas.clientWidth;
  const canvasHeight = canvas.clientHeight;
  // Create an image object (only need one instance)
  const imageObj = new Image();
  // Once the image has been downloaded then set the image on all of the particles
  imageObj.onload = function() {
    particles.forEach(function (particle) {
      particle.setImage(imageObj);
    });
  };
  // Once the callback is arranged then set the source of the image
  imageObj.src = loadAsset('img/smoke_particle_2.png');
  // A function to create a particle object.
  class Particle {
    x = 0;
    y = 0;
    xVelocity = 0;
    yVelocity = 0;
    radius = 80;
    image?: HTMLImageElement;
    constructor(private context: CanvasRenderingContext2D) {}
    draw() {
      // If an image is set draw it
      if(this.image){
          this.context.drawImage(this.image, this.x-128, this.y-128);         
          // If the image is being rendered do not draw the circle so break out of the draw function                
          return;
      }
      // Draw the circle as before, with the addition of using the position and the radius from this object.
      this.context.beginPath();
      this.context.arc(this.x, this.y, this.radius, 0, 2 * Math.PI, false);
      this.context.fillStyle = "rgba(255, 255, 255, 0.5)";
      this.context.fill();
      this.context.closePath();
    };
    update() {
      // Update the position of the particle with the addition of the velocity.
      this.x += this.xVelocity;
      this.y += this.yVelocity;
      // Check if has crossed the right edge
      if (this.x >= canvasWidth) {
          this.xVelocity = -this.xVelocity;
          this.x = canvasWidth;
      }
      // Check if has crossed the left edge
      else if (this.x <= 0) {
          this.xVelocity = -this.xVelocity;
          this.x = 0;
      }
      // Check if has crossed the bottom edge
      if (this.y >= canvasHeight) {
          this.yVelocity = -this.yVelocity;
          this.y = canvasHeight;
      }
      // Check if has crossed the top edge
      else if (this.y <= 0) {
          this.yVelocity = -this.yVelocity;
          this.y = 0;
      }
    };
    setPosition(x: number, y: number) {
      this.x = x;
      this.y = y;
    };
    setVelocity(x: number, y: number) {
      this.xVelocity = x;
      this.yVelocity = y;
    };
    setImage(image: HTMLImageElement){
      this.image = image;
    }
  }

  // A function to generate a random number between 2 values
  function generateRandom(min: number, max: number){
      return Math.random() * (max - min) + min;
  }

  // Initialise the scene and set the context if possible
  function init(): CanvasRenderingContext2D|null {
    // Set the context variable so it can be re-used
    let context = canvas.getContext('2d');
    if (!context) { return null; }

    // Create the particles and set their initial positions and velocities
    for (var i = 0; i < particleCount; ++i) {
      var particle = new Particle(context);
      // Set the position to be inside the canvas bounds
      particle.setPosition(generateRandom(0, canvasWidth), generateRandom(0, canvasHeight));
      // Set the initial velocity to be either random and either negative or positive
      particle.setVelocity(generateRandom(-maxVelocity, maxVelocity), generateRandom(-maxVelocity, maxVelocity));
      particles.push(particle);
    }
    return context;
  }

  // The function to draw the scene
  function draw(context: CanvasRenderingContext2D) {
      // Clear the drawing surface and fill it with a black background
      context.fillStyle = "rgba(0, 0, 0, 0.5)";
      context.fillRect(0, 0, 400, 400);

      // Go through all of the particles and draw them.
      for (let particle of particles) {
        particle.draw();
      }
  }

  // Update the scene
  function update() {
    for (let particle of particles) {
      particle.update();
    }
  }

  // Initialize the scene
  const context = init();
  if (!context) { return; }
  const interval = setInterval(() => {
    // Update the scene befoe drawing
    update();
    // Draw the scene
    draw(context);
  }, 1000 / targetFPS);
  return interval;
}