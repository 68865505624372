import { useEffect, useRef, useState } from "react";
import { loadAsset, QuestProps } from "../cyoa-manifest";
import Quest from "../components/Quest";
import './knowledge.scss';

export default function KnowledgeQuest(props: QuestProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const data = props.quest.data as {
    title: string;
    logo: string;
    prompt: string;
    question: string;
    options: string[];
    correctOption: number;
  };
  const [success, setSuccess] = useState<boolean>(false);
  const [selected, setSelected] = useState<number|undefined>(undefined);

  useEffect(() => { inputRef.current?.focus(); }, []);

  const onClickOption = (e: React.FormEvent, index: number) => {
    if (success) { return; }
    setSelected(index);
    if (index === data.correctOption) {
      setSuccess(true);
    }
  }
  const alphabet = 'abcdefg';

  return data
    ? <Quest success={success} quest={props.quest}>
      <div className="KnowledgeQuest">
        <div className="container">
          <div className="header">
            <img className="logo" src={loadAsset(data.logo)} alt="logo"></img>
            <div className="title">{data.title}</div>
          </div>
          <hr></hr>
          <div className="prompt">{data.prompt}</div>
          <div className="question">{data.question}</div>
          <div className={`options ${success ? 'correct' : ''}`}>
            {data.options.map((o, i) => <div
              key={i}
              className={`option ${selected===i?'selected':''} ${success?'success':'' }`}
              onClick={(e) => onClickOption(e, i)}
              >
              <div className="index">{alphabet[i]}</div>
              <div className="value">{o}</div>
            </div>)}
          </div>
        </div>
      </div>
    </Quest>
    : <></>;
}