import { MutableRefObject, useEffect, useState } from 'react'
import MonacoEditor from "@monaco-editor/react";
import { ReactEditableProperties } from './EditableReact'
import { Editable } from './editable.interface'
import { EditableUtil } from './EditableUtil'
import useTrig from 'react-use-trig';

function getEditorLanguage(editable: Editable, editProperty: string) {
  const info = EditableUtil.getPropertyInfo(editable, editProperty)
  return info ? info.lang : 'text'
}

export function getEditorValue(editable: Editable, editProperty: string, value: string) {
  return EditableUtil.packForStorage(editable, editProperty, value || '')
}

function getEditorInputValue(editable: Editable, editProperty: string) {
  const value = editProperty in editable ? (editable as any)[editProperty] : ''
  if (!value) { return value }
  try {
    return EditableUtil.unpackForEditing(editable, editProperty, value)
  } catch (error: any) {
    return error.message
  }
}

export default function MonacoAdapter(props: {
  editable: Editable,
  editProperty: string,
  edits: string,
  onEdit?: (editProperty: string, value: string) => void,
  editorRef: MutableRefObject<any>,
  monacoRef: MutableRefObject<any>
}) {

  const { trig } = useTrig()
  const [changing, setChanging] = useState(false)

  const handlers = {
    onChange(value: string|undefined) {
      console.log('onChange', value)
      props.onEdit && props.onEdit(props.editProperty, value || '')
    },
    onMount(editor: any, monaco: any) {
      props.editorRef.current = editor 
      props.monacoRef.current = monaco
      console.log({
        props
      })
    }
  }

  // This is to force monaco to recalculate its height because
  // it grows outside of its container when setting new text
  // values without a full re-render
  useEffect(() => {
    setChanging(true)
    trig({ f: () => { setChanging(false) }, t: 1 })
  }, [props.editProperty, trig, setChanging])

  if (changing) {
     return <></>
  }

  return <MonacoEditor
    theme="vs-dark"
    value={props.edits || (props.editable ? getEditorInputValue(props.editable, props.editProperty): '')}
    language={props.editable ? getEditorLanguage(props.editable, props.editProperty) : 'javascript'}
    options={{
      // automaticLayout: true
      autoIndent: true,
    }}
    {...handlers}
  />
}