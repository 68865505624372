import { useCallback, useEffect, useState } from "react";
import StoryContext, { AudioConfig, CharacterConfig, EventConfig, EventKey, NarrationStepConfig, SceneConfig, ScenePath, StoryContextState } from "./story";
import EventEmitter from "events";
interface SharedStoryState {
  scenePath: ScenePath,
  eventKey?: EventKey,
  narrator?: CharacterConfig,
  narrationStepConfig?: NarrationStepConfig,
  narrationStepIndex: number,
  scenePaths: ScenePath[],
  sceneConfig: SceneConfig,
  eventConfig?: EventConfig,
  audio?: AudioConfig,
  allowEvents: EventKey[],
  openingScene: ScenePath,
  stream: EventEmitter,
  successAudio?: AudioConfig,
  failureAudio?: AudioConfig,
  emit: (key: EventKey, force?: boolean) => void,
  start: (path: ScenePath) => void,
  step: () => void,
}

const shared: {
  story: SharedStoryState;
} = ({} as unknown as { story: SharedStoryState });
export { 
  shared,
}

export default function useStory(): SharedStoryState {
  const story = StoryContext.instance;
  const [ scenePath, setScenePath ] = useState<ScenePath>('');
  const [ eventKey, setEventKey ] = useState<EventKey>('');
  const [ narrator, setNarrator ] = useState<CharacterConfig|undefined>(undefined);
  const [ narrationStepConfig, setNarrationStepConfig ] = useState<NarrationStepConfig|undefined>(undefined);
  const [ narrationStepIndex, setNarrationStepIndex ] = useState<number>(0);
  const [ sceneConfig, setSceneConfig ] = useState<SceneConfig>({});
  const [ eventConfig, setEventConfig ] = useState<EventConfig|undefined>(undefined);
  const [ audio, setAudio ] = useState<AudioConfig|undefined>(undefined);
  const [ allowEvents, setAllowEvents ] = useState<EventKey[]>([]);

  const setScene = (c: StoryContextState) => {
    console.log('stream: scene', c);
    setScenePath(c.scenePath);
    setSceneConfig(c.sceneConfig);
    if (c.narrator !== narrator) {
      setNarrator(c.narrator);
    }
  };

  const setEvent = (c: StoryContextState) => {
    console.log('stream: event', c);
    setEventKey(c.eventKey);
    setEventConfig(c.eventConfig);
    setAllowEvents(c.allowEvents);
    if (c.audio !== audio) {
      setAudio(c.audio);
    }
    if (c.narrator !== narrator) {
      setNarrator(c.narrator);
    }
  };

  const setStep = (c: StoryContextState) => {
    console.log('stream: step', c);
    setNarrationStepIndex(c.narrationStepIndex);
    setNarrationStepConfig(c.narrationStepConfig);
    setAllowEvents(c.allowEvents);
  };

  useStoryStream('scene', setScene);
  useStoryStream('event', setEvent);
  useStoryStream('step', setStep);

  return {
    scenePath,
    eventKey,
    narrator,
    narrationStepConfig,
    narrationStepIndex,
    sceneConfig,
    eventConfig,
    audio,
    allowEvents,
    scenePaths: story.scenePaths,
    openingScene: story.openingScene,
    successAudio: story.successAudio,
    failureAudio: story.failureAudio,
    stream: story.stream,
    emit: useCallback((key: EventKey, force: boolean = false) => story.produce(key, force), [story]),
    start: useCallback((path: ScenePath) => story.start(path), [story]),
    step: useCallback(() => story.step(), [story]),
  };
}

export function useStoryStream(eventName: string, handler: (data: any) => void) {
  const story = StoryContext.instance;
  useEffect(() => {
    story.stream.on(eventName, handler);
    return () => {
      story.stream.off(eventName, handler);
    }
  });
}

export function useEvent(effectRecord: Record<EventKey, () => void>) {
  const {eventKey} = shared.story;
  useEffect(() => {
    if (!eventKey) { return; }
    const runEffect = effectRecord[eventKey];
    if (typeof runEffect === 'function') {
      runEffect();
    }
  // eslint-disable-next-line
  }, []); // Only do this the first time in case the event is current event
  useStoryStream('event', (contextState: StoryContextState) => {
    const runEffect = effectRecord[contextState.eventKey];
    if (typeof runEffect === 'function') {
      runEffect();
    }
  });
}

export function preloadAssets(onProgress: (p: { current: number, total: number}) => void) {
  StoryContext.preloadAssets(onProgress);
}