import { loadAsset } from '../cyoa-manifest';
import { Knot } from '../util/Knot';
import './rings.scss';

export interface RingsProps {
  show: boolean;
}

/**
 * Primary UI component for user interaction
 */
export default function Rings(props: RingsProps): JSX.Element {
  return Knot({
    _: props.show,
    names: ['merge-rings'],
    x: (p: any) => <div {...p}>
      <img className="ring-1" src={loadAsset('img/gold-ring.png')} alt="hero-ring" />
      <img className="ring-2" src={loadAsset('img/gold-ring.png')} alt="companion-ring" />
    </div>,
  })
};

