interface KnotArgs extends Record<string, any> {
  _: boolean;
  x: (props: any) => JSX.Element;
  names?: string[];
}

export function Knot(args: KnotArgs): JSX.Element {
  const className = Object.entries(args).reduce((names: string[], [k, v]) => {
    if (['_', 'x'].includes(k)) { return names; }
    if (k === 'names') {
      names.push((v as string[]).join(' '))
    } else {
      v && names.push(k);
    }
    return names;
  }, []).join(' ');
  return args._ ? args.x({ className }) : <></>;
}