import { loadAsset } from '../cyoa-manifest';
import { Knot } from '../util/Knot';
import './fellowship.scss';

export interface HouseCollageProps {
  show: boolean;
  showRing: boolean;
  selectRing: boolean;
  showDialogue1: boolean;
  walking: boolean;
  onClickRing: (e: React.MouseEvent) => void;
}

export default function Fellowship(props: HouseCollageProps): JSX.Element {
  const lines = [
    'Hello, I am Hidden Beauty of the Middle Way.',
    'Want to walk the Path of Life with me?',
  ]

  const ringKnot = Knot({
    _: props.showRing,
    names: ['ring'],
    x: (p) => <div {...p} onClick={e => props.onClickRing(e)}>
      <img src={loadAsset('img/ring.png')} alt="A ring" onClick={() => {}}></img>
    </div>,
    selected: props.selectRing,
  });

  const heroKnot = Knot({
    _: true,
    x: (p) => <div {...p}>
      <img src={loadAsset('img/guy.gif')} alt="Man of the Pleasant Peninsula"></img>
    </div>,
    names: ['hero'],
    walking: props.walking,
  });

  const companionKnot = Knot({
    _: true,
    names: ['companion'],
    x: (p) => <div {...p}>
      <img src={loadAsset('img/girl-nose-ring-left.gif')} alt="Hidden Beauty of the Middle Way"></img>
      <div className="text-box"></div>
    </div>,
    walking: props.walking,
  })

  const dialogueKnot = Knot({
    _: props.showDialogue1,
    x: (p) => <div {...p}>
      {lines.map((line, i) => <div key={i}>{line}</div>)}
    </div>,
    names: ['dialogue', 'dialogue-1'],
  })

  const fellowshipKnot = Knot({
    _: props.show,
    x: (p) => <div {...p}>
      {heroKnot}
      {ringKnot}
      {companionKnot}
      {dialogueKnot}
    </div>,
    names: ['Fellowship'],
    walking: props.walking,
  });
  return fellowshipKnot;
}


