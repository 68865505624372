import { useRef, useState } from "react";
import { loadAsset } from "../cyoa-manifest";
import { Knot } from "../util/Knot";
import './fight.scss';

export function FightEvent() {
  const random = (min: number, max: number) => Math.round(Math.random() * (max - min)) + min;
  const containerRef = useRef<HTMLDivElement>(null);
  const [balls] = useState<number[]>(Array(100).fill(0));
  return <div
    className='FightEvent'
    ref={containerRef}
    >
    {balls.map((_, i) => Knot({
      _: true,
      names: ['ball'],
      x: p => <img {...p}
        key={i}
        src={loadAsset(`img/mr_bucket_${random(0, 1) ? 'blue' : 'green'}_ball.png`)} alt='weaponized ball'
        style={{
          left: random(1, 100) + '%',
          animation: `fall ${random(1000, 2000)/1000}s ease-out ${random(1000, 100000) / 1000}s 1 normal forwards`,
        }}
        />
    }))}
  </div>
}