import { loadAsset } from '../cyoa-manifest';
import { Knot } from '../util/Knot';
import './house.scss';

export interface HouseProps {
  show: boolean;
}

export default function House(props: HouseProps): JSX.Element {
  return Knot({
    _: props.show,
    x: (p) => <div {...p}>
      <img src={loadAsset('img/house.png')} alt="A House"></img>
    </div>,
    names: ['house'],
  })
};

