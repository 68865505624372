import { Route, Switch } from 'react-router-dom';
import { RestfulProvider } from "restful-react";
import { EditableCollectionType } from './editable.interface';
import Editor from './Editor'

const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://api.adventure.cards'
  : 'http://localhost:53534'


export default function Creator() {
  // const { user, setUser } = useContext(AuthContext)

  return <RestfulProvider base={baseURL}>
    {/* <AuthContext.Provider setUser={setUser} user={user} isAuthenticated={false} > */}
        <Switch>
          {/* <Route path={`/create/cards/:id`}>
            <Editor collectionType={'cards'}/>
          </Route> */}
          {Object.values(EditableCollectionType).map((collectionType) => {
            return <Route key={collectionType} path={`/create/${collectionType}/:id`}>
              <Editor collectionType={collectionType}/>
            </Route>
          })}
        </Switch>
    {/* </AuthContext.Provider> */}
  </RestfulProvider>
}