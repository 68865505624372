import { useState } from "react";
import { useEvent } from "../engine/useStory";
import RingEvent from "../events/ring";
import Wheel from "../stories/Wheel";

export default function FractalForestScene() {
  const [eventComponent, setEventComponent] = useState<JSX.Element|null>(null);
  useEvent({
    ring: () => {
      setEventComponent(<RingEvent/>);
    },
  });
  return <>
    <Wheel center={false} hide={false} intro={false} fixed={true} falling={false} bounce={false} level={4} outro={true}></Wheel>
    { eventComponent }
  </>

}