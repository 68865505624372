import StartScene from './scenes/start';
import OpeningCrawlScene from './scenes/opening-crawl';
import RunningInCirclesScene from './scenes/running-in-circles';
import AdventureScene from './scenes/adventure';
import FractalForestScene from './scenes/fractal-forest';
import { FilePath, QuestConfig } from './engine/story';
import NasQuest from './quests/nas';
import MrBucketQuest from './quests/mr_bucket';
import PizzaQuest from './quests/pizza';
import KnowledgeQuest from './quests/knowledge';

const sceneMap: Record<string, () => JSX.Element> = {
  StartScene,
  OpeningCrawlScene,
  RunningInCirclesScene,
  AdventureScene,
  FractalForestScene,
};

export interface QuestProps {
  quest: QuestConfig;
}

const questMap: Record<string, (props: QuestProps) => JSX.Element> = {
  NasQuest,
  MrBucketQuest,
  PizzaQuest,
  KnowledgeQuest,
};

function loadAsset(file?: FilePath) {
  if (!file) {
    console.warn('no file specified to load asset')
    return undefined;
  }
  const loaded = require(`./${file}`)
  if (!loaded) {
    console.warn('unable to find file to load asset')
    return undefined
  }
  return loaded
}


export { sceneMap, questMap, loadAsset };
