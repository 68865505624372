import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import { useFetch, useRequest } from "../hooks/useAPI";
import Preview from './Preview'
import MonacoAdapter from './MonacoAdaptor'
import EntityError from './EntityError'
import { ReactEditableProperties } from './EditableReact'
import { Editable, EditableCollectionType } from "./editable.interface";
import { EditableUtil } from "./EditableUtil";
import './Editor.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'highlight.js/styles/night-owl.css';

hljs.registerLanguage('javascript', javascript);
hljs.highlightAll();

export default function Editor(props: {
  collectionType: EditableCollectionType
}) {
  const { id } = useParams()

  const editorRef = useRef<any>(null)
  const monacoRef = useRef<any>(null)

  const { data, hasError, isLoading, error: fetchError, refetch } = useFetch(`/${props.collectionType}/${id}`)
  const { post, put, isLoading: isSaving, error: updateError, result: updateResult } = useRequest(`/${props.collectionType}/${id}`, refetch)

  const previewRef= useRef<HTMLIFrameElement>(null)

  const [editable, setEditable] = useState<Editable|null>(null)
  const [edits, setEdits] = useState<Partial<Editable>>({})

  const [showPreview, setShowPreview] = useState(true)
  const [editProperty, setEditProperty] = useState<string>(ReactEditableProperties.Component)
  const [layout, setLayout] = useState({
    direction: 'row'
  })

  const [previewMessage, setPreviewMessage] = useState()

  useEffect(() => {
    if (updateResult?.status === 200) {
      toast.success('Saved', { autoClose: 1200 })
      setEdits({ ...edits, [editProperty]: undefined })
      if (previewRef.current) {
        // Reload the preview when saved
        previewRef.current.src = previewRef.current.src
      }
    }
  }, [updateResult])

  useEffect(() => {
    setEditable(data?.attributes ? data.attributes : null)
  }, [data, setEditable])

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (event.data && typeof event.data === 'object' && event.data.__adventure) {
        setPreviewMessage(event.data.data)
      }
    }

    window.addEventListener("message", handler)

    return () => window.removeEventListener("message", handler)
  }, [])

  useEffect(() => {
    if (previewMessage) {
      toast(<pre>{JSON.stringify(previewMessage, null, 2)}</pre>)
    }
  }, [previewMessage])

  const getEditorValue = () => {
    const value = editorRef.current?.getValue() || ''
    return editable
      ? EditableUtil.packForStorage(editable, editProperty, value)
      : value
  }

  const onSave = async () => {
    editorRef.current?.getAction('editor.action.formatDocument').run();

    const markers = monacoRef.current.editor.getModelMarkers()

    if (!markers.length) {
      let value
      try {
        value = getEditorValue()
      } catch(error: any) {
        toast.error(error.message)
        return
      }
      put({ data: { ...editable, [editProperty]: value } })
    } else {
      // Tell the user about the problems
      markers.forEach((marker: any) => {
        toast.warn(<>{marker.message}<br /><pre>{JSON.stringify({
          severity: marker.severity,
          startLineNumber: marker.startLineNumber,
          endLineNumber: marker.endLineNumber,
          startColumn: marker.startColumn,
          endColumn: marker.endColumn,
        }, null, 2)}</pre></>, { autoClose: false })
      })
    }
  }

  const onEdit = (property: string, value: string) => {
    const issameAsInitial = editable && value === EditableUtil.unpackForEditing(editable, property, (editable as any)[property])
    setEdits({ ...edits, [property]: issameAsInitial ? undefined : value })
  }

  const onCreate = async() => {
    post({ data: { slug: id } })
  }

  const {head, tail} = EditableUtil.getCodeFrame(editProperty, id || '', editable)

  const previewSrc = `http://localhost:53535/c/${id}`

  return <div className="editor">

    <ToastContainer theme={'dark'} position="bottom-right" pauseOnFocusLoss={false} />

    <div className="action-bar">
      <div className="tab-bar">
        {
          [ReactEditableProperties.Component, ReactEditableProperties.SCSS, ReactEditableProperties.DefaultProps].map(prop => {
            return <button
              disabled={editProperty === prop}
              key={prop}
              onClick={() => {
                prop !== editProperty && setEditProperty(prop);
              }}
            >{prop} {(edits as any)[prop] && '🔺'}</button>
          })
        }
      </div>
      <div className="actions">
        {editable && <button onClick={onSave}>💾</button>}
        <button onClick={() => setShowPreview(!showPreview)}>{showPreview ? '🆇' : '👁'}</button>
        <a href={previewSrc} target="_blank">🌐</a>
      </div>
    </div>

    <div className="info-bar">
    </div>

    <div className="panels">
      <div className="panel">
        <span className="wrapper-code">
          {head}
        </span>
        <div className="wrapper-editor">
          {editable && <MonacoAdapter {...{
            editable, editProperty, editorRef, monacoRef, onEdit, edits: (edits as any)[editProperty]
          }}></MonacoAdapter>}
          {hasError && !isSaving && !isLoading && <EntityError collectionType={props.collectionType} error={fetchError} onCreate={onCreate} />}
        </div>
        <span className="wrapper-code">
          {tail}
        </span>
      </div>
      <div className="panel">
        {showPreview && <Preview previewRef={previewRef} src={previewSrc}></Preview>}
      </div>
    </div>
  </div>
}
