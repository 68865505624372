import './running-in-circles.scss';
import Scene from '../components/Scene';
import Wheel from '../stories/Wheel';
import { shared, useEvent } from '../engine/useStory';
import { useState } from 'react';
import useTrig from 'react-use-trig';
import { loadAsset } from '../cyoa-manifest';

export default function RunningInCirclesScene() {
  const { emit } = shared.story;
  const [wheelVisible, setWheelVisible] = useState<boolean>(false);
  const [falling, setFalling] = useState<boolean>(false);
  const [running, setRunning] = useState<boolean>(false);
  const [fixed, setFixed] = useState<boolean>(false);
  const [intro, setIntro] = useState<boolean>(true);
  const { trig } = useTrig();
  useEvent({
    falling: () => {
      trig([
        { t: 3000, f: () => {
          setWheelVisible(true);
          setFalling(true)
        }},
        { t: 16000, f: () => {
          emit('running');
        }}
      ]);
    },
    running: () => {
      setFalling(false);
      setRunning(true);
    },
    progress: () => {
      setRunning(false);
      setFixed(true);
      trig({ t: 14000, f: () => {
        setIntro(false);
        emit('begin_adventure');
      }})
    },
  });

  return <Scene>
    { wheelVisible ? <Wheel center={false} hide={false} intro={intro} fixed={fixed} falling={falling} bounce={running} level={1} outro={false}></Wheel> : <></> }
    <img className='lost-bike' alt='bike with missing front wheel' src={loadAsset('img/bike.png')} />
  </Scene>
}