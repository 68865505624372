import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import TWEEN from '@tweenjs/tween.js';

function clearCaches() {
  if (!window.caches) { return; }
  window.caches.keys().then(function(names) {
    for (let name of names) {
      caches.delete(name);
    }
  });
}

const CACHE_VERSION = { key: 'CACHE_VERSION', value: '0.0.2' };
if (CACHE_VERSION.value !== localStorage.getItem(CACHE_VERSION.key)) {
  localStorage.setItem(CACHE_VERSION.key, CACHE_VERSION.value);
  clearCaches();
  window.location.reload();
}
window.addEventListener('keypress', (e) => {
  if (e.key === 'R') {
    console.log('clearing cache');
    clearCaches();
    window.location.reload();
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

function animate(time: number) {
	requestAnimationFrame(animate)
	TWEEN.update(time)
}
requestAnimationFrame(animate)
